import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Slide,
  Menu,
  MenuItem,
  Box,
  Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { nanoid } from 'nanoid';
import { addEdge, applyNodeChanges, applyEdgeChanges } from 'reactflow';
import FlowCanvas from './FlowCanvas';
import RetrievalBlockConfig from './RetrievalBlockConfig';
import TransformationBlockConfig from './TransformationBlockConfig';
import VisualizationBlockConfig from './VisualizationBlockConfig';
import { mainAuth, db } from '../../services/firebase';
import { getIdToken } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FunctionBuilderDialog({
  open,
  onClose,
  onSave,
  tables = [],
  companyName,
  functionId
}) {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [configuringNodeId, setConfiguringNodeId] = useState(null);
  const [configuringNodeType, setConfiguringNodeType] = useState(null);
  const [configuringNodeConfig, setConfiguringNodeConfig] = useState({});

  const [error, setError] = useState('');
  const [functionDoc, setFunctionDoc] = useState(null);
  const [loadingFunction, setLoadingFunction] = useState(false);

  const loadFunctionDoc = useCallback(async () => {
    if (!companyName || !functionId) return;
    setLoadingFunction(true);
    const docRef = doc(db, `users/${companyName}/data_functions`, functionId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const fData = docSnap.data();
      setFunctionDoc(fData);
      if (fData.nodes) setNodes(fData.nodes);
      if (fData.edges) setEdges(fData.edges);
    }
    setLoadingFunction(false);
  }, [companyName, functionId]);

  useEffect(() => {
    if (open) {
      loadFunctionDoc();
    }
  }, [open, loadFunctionDoc]);

  const handleOpenMenu = (event) => setMenuAnchor(event.currentTarget);
  const handleCloseMenu = () => setMenuAnchor(null);

  const onNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const onEdgesChange = useCallback((changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  // Save updated nodes/edges to Firestore without re-loading
  const saveNodesAndEdges = async (newNodes, newEdges) => {
    if (!companyName || !functionId) return;
    const docRef = doc(db, `users/${companyName}/data_functions`, functionId);
    await updateDoc(docRef, {
      nodes: newNodes,
      edges: newEdges
    });
  };

  const onConnect = useCallback(async (params) => {
    let newEdges = addEdge(params, edges);
    setEdges(newEdges);
    await saveNodesAndEdges(nodes, newEdges);

    const sourceNode = nodes.find(n => n.id === params.source);
    const targetNode = nodes.find(n => n.id === params.target);

    if (!companyName || !functionId) return;
    const docRef = doc(db, `users/${companyName}/data_functions`, functionId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) return;
    const fDoc = docSnap.data();

    if (sourceNode && targetNode &&
        sourceNode.data?.type === 'retrieval' &&
        targetNode.data?.type === 'transformation' &&
        sourceNode.data.blockId && targetNode.data.blockId) {
      const retrieval_blocks = fDoc.retrieval_blocks || {};
      const rBlockId = sourceNode.data.blockId;
      const tBlockId = targetNode.data.blockId;

      if (retrieval_blocks[rBlockId]) {
        retrieval_blocks[rBlockId].transformation_block_id = tBlockId;
        await updateDoc(docRef, { retrieval_blocks });
      }
    }

    if (sourceNode && targetNode &&
        sourceNode.data?.type === 'transformation' &&
        targetNode.data?.type === 'visualization' &&
        sourceNode.data.blockId && targetNode.data.blockId) {
      const transformation_blocks = fDoc.transformation_blocks || {};
      const tBlockId = sourceNode.data.blockId;
      const vBlockId = targetNode.data.blockId;

      if (transformation_blocks[tBlockId]) {
        transformation_blocks[tBlockId].visualization_block_id = vBlockId;
        await updateDoc(docRef, { transformation_blocks });
      }
    }
  }, [edges, nodes, companyName, functionId]);

  const addNode = async (type) => {
    let nodeTypeKey;
    if (type === 'retrieval') nodeTypeKey = 'retrievalNode';
    if (type === 'transformation') nodeTypeKey = 'transformationNode';
    if (type === 'visualization') nodeTypeKey = 'visualizationNode';

    const id = `node_${type}_${nanoid(6)}`;
    const newNode = {
      id,
      type: nodeTypeKey,
      position: { x: Math.random() * 500, y: Math.random() * 300 },
      data: {
        type,
        config: {}
      }
    };
    const updatedNodes = [...nodes, newNode];
    setNodes(updatedNodes);

    await saveNodesAndEdges(updatedNodes, edges);
    const docRef = doc(db, `users/${companyName}/data_functions`, functionId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) return;

    const fDoc = docSnap.data();

    let retrieval_blocks = fDoc.retrieval_blocks || {};
    let transformation_blocks = fDoc.transformation_blocks || {};
    let visualization_blocks = fDoc.visualization_blocks || {};

    if (type === 'retrieval') {
      const blockId = `retr_block_${nanoid(6)}`;
      newNode.data.blockId = blockId;
      retrieval_blocks[blockId] = {};
      await updateDoc(docRef, {
        retrieval_blocks,
        nodes: updatedNodes
      });
    }

    if (type === 'transformation') {
      const blockId = `trans_block_${nanoid(6)}`;
      newNode.data.blockId = blockId;
      transformation_blocks[blockId] = {
        id: blockId,
        name: '',
        configured: false
      };
      await updateDoc(docRef, {
        transformation_blocks,
        nodes: updatedNodes
      });
    }

    if (type === 'visualization') {
      const blockId = `viz_block_${nanoid(6)}`;
      newNode.data.blockId = blockId;
      visualization_blocks[blockId] = {};
      await updateDoc(docRef, {
        visualization_blocks,
        nodes: updatedNodes
      });
    }
  };

  const handleConfigureNode = (nodeId) => {
    const node = nodes.find((n) => n.id === nodeId);
    if (!node) return;
    setConfiguringNodeId(nodeId);
    setConfiguringNodeType(node.data.type);
    setConfiguringNodeConfig(node.data.config || {});
  };

  const handleConfigCancel = () => {
    setConfiguringNodeId(null);
    setConfiguringNodeType(null);
    setConfiguringNodeConfig({});
  };

  const handleRetrievalBlockSave = async (blockConfig) => {
    if (!companyName || !functionId || !configuringNodeId) return;

    const docRef = doc(db, `users/${companyName}/data_functions`, functionId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) return;
    const fDoc = docSnap.data();

    let retrieval_blocks = fDoc.retrieval_blocks || {};
    const node = nodes.find(n => n.id === configuringNodeId);
    if (!node) return;

    let blockId = node.data.blockId;
    if (!blockId) {
      blockId = `retr_block_${nanoid(6)}`;
      node.data.blockId = blockId;
    }

    retrieval_blocks[blockId] = {
      id: blockId,
      name: blockConfig.name || '',
      table_id: blockConfig.table_id || '',
      filters: blockConfig.filters || [],
      preview_data: blockConfig.preview_data || [],
      configured: true
    };

    node.data.config = { ...blockConfig, blockId, configured: true };

    await updateDoc(docRef, {
      retrieval_blocks,
      nodes
    });
    // Removed await loadFunctionDoc(); 
    // Trust the updated local state now

    handleConfigCancel();
  };

  const handleTransformationBlockSave = async (blockConfig) => {
    if (!companyName || !functionId || !configuringNodeId) return;

    const docRef = doc(db, `users/${companyName}/data_functions`, functionId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) return;
    const fDoc = docSnap.data();

    let transformation_blocks = fDoc.transformation_blocks || {};
    const node = nodes.find(n => n.id === configuringNodeId);
    if (!node) return;

    let blockId = node.data.blockId;
    if (!blockId) {
      blockId = `trans_block_${nanoid(6)}`;
      node.data.blockId = blockId;
      transformation_blocks[blockId] = {
        id: blockId,
        name: '',
        configured: false
      };
    }

    if (transformation_blocks[blockId]) {
      transformation_blocks[blockId].name = blockConfig.name || '';
    }

    node.data.config = { ...blockConfig, blockId };

    await updateDoc(docRef, {
      transformation_blocks,
      nodes
    });
    handleConfigCancel();
  };

  const handleVisualizationBlockSave = async (blockConfig) => {
    if (!companyName || !functionId || !configuringNodeId) return;

    const docRef = doc(db, `users/${companyName}/data_functions`, functionId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) return;
    const fDoc = docSnap.data();

    let visualization_blocks = fDoc.visualization_blocks || {};
    const node = nodes.find(n => n.id === configuringNodeId);
    if (!node) return;

    let blockId = node.data.blockId;
    if (!blockId) {
      blockId = `viz_block_${nanoid(6)}`;
      node.data.blockId = blockId;
      visualization_blocks[blockId] = {};
    }

    visualization_blocks[blockId] = {
      ...visualization_blocks[blockId],
      id: blockId,
      ...blockConfig,
      configured: true
    };

    node.data.config = { ...node.data.config, ...blockConfig, blockId };

    await updateDoc(docRef, {
      visualization_blocks,
      nodes
    });
    // Removed await loadFunctionDoc();

    handleConfigCancel();
  };

  const handleSaveFunctionAndClose = async () => {
    if (!companyName || !functionId) return;
    const docRef = doc(db, `users/${companyName}/data_functions`, functionId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) return;

    const currentFunction = docSnap.data();
    onSave({ ...currentFunction, id: functionId });
  };

  if (!open || loadingFunction) {
    return null;
  }

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Build Function
          </Typography>
          <Button color="inherit" startIcon={<AddIcon />} onClick={handleOpenMenu}>
            Add Node
          </Button>
          <Button color="inherit" onClick={handleSaveFunctionAndClose}>
            Finalize Function
          </Button>
        </Toolbar>
      </AppBar>
      <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleCloseMenu}>
        <MenuItem onClick={() => {handleCloseMenu(); addNode('retrieval');}}>Retrieval Block</MenuItem>
        <MenuItem onClick={() => {handleCloseMenu(); addNode('transformation');}}>
          Transformation Block
        </MenuItem>
        <MenuItem onClick={() => {handleCloseMenu(); addNode('visualization');}}>Visualization Block</MenuItem>
      </Menu>

      {error && <Alert severity="error" onClose={() => setError('')}>{error}</Alert>}

      <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
        <FlowCanvas
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onConfigureNode={handleConfigureNode}
        />
      </Box>

      {configuringNodeId && configuringNodeType === 'retrieval' && (
        <RetrievalBlockConfig
          initialConfig={configuringNodeConfig}
          tables={tables}
          companyName={companyName}
          onPreviewRequest={async (table_id, filters, startDate, endDate) => {
            const idToken = await getIdToken(mainAuth.currentUser);
            const headers = {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`
            };
            const preview = true;
            const response = await fetch('https://europe-west2-biminsurance-d5783.cloudfunctions.net/get_table_data', {
              method: 'POST',
              headers: headers,
              body: JSON.stringify({
                company_id: companyName,
                table_id,
                preview,
                filters: {
                  ...filters,
                  startDate,
                  endDate
                }
              })
            });

            if (!response.ok) {
              throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            console.log(data);
            return data.rows || [];
          }}
          onSave={handleRetrievalBlockSave}
          onCancel={handleConfigCancel}
        />
      )}

      {configuringNodeId && configuringNodeType === 'transformation' && (
        <TransformationBlockConfig
          initialConfig={configuringNodeConfig}
          companyName={companyName}
          functionId={functionId}
          blockId={(() => {
            const node = nodes.find(n => n.id === configuringNodeId);
            return node?.data?.blockId;
          })()}
          onSave={handleTransformationBlockSave}
          onCancel={handleConfigCancel}
        />
      )}

      {configuringNodeId && configuringNodeType === 'visualization' && (
        <VisualizationBlockConfig
          initialConfig={configuringNodeConfig}
          dataSample={[]}
          onSave={handleVisualizationBlockSave}
          onCancel={handleConfigCancel}
          companyName={companyName}
          functionId={functionId}
          blockId={(() => {
            const node = nodes.find(n => n.id === configuringNodeId);
            return node?.data?.blockId;
          })()}
        />
      )}
    </Dialog>
  );
}

export default FunctionBuilderDialog;
