import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  FormGroup, 
  FormControlLabel, 
  Checkbox, 
  Button, 
  Paper,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  CircularProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth } from '../contexts/AuthContext';
import { useDashboard } from '../contexts/DashboardContext';
import { db } from '../services/firebase';
import { doc, setDoc, collection, getDocs } from 'firebase/firestore';

function Settings() {
  const { currentUser, companyName } = useAuth();
  const { showTooltips, setShowTooltips } = useDashboard();
  const [tables, setTables] = useState([]);
  const [displaySettings, setDisplaySettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Fetch tables and their display settings
  useEffect(() => {
    const fetchTables = async () => {
      if (!companyName) return;

      try {
        setLoading(true);
        const tablesCollection = collection(db, `users/${companyName}/tables`);
        const tablesSnapshot = await getDocs(tablesCollection);
        const tablesList = tablesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setTables(tablesList);
        
        // Initialize display settings from tables
        const settings = {};
        tablesList.forEach(table => {
          settings[table.id] = table.display_columns || {};
        });
        setDisplaySettings(settings);
      } catch (err) {
        console.error('Error fetching tables:', err);
        setError('Failed to load tables');
      } finally {
        setLoading(false);
      }
    };

    fetchTables();
  }, [companyName]);

  const handleColumnToggle = (tableId, columnName) => {
    setDisplaySettings(prev => ({
      ...prev,
      [tableId]: {
        ...prev[tableId],
        [columnName]: !prev[tableId][columnName]
      }
    }));
  };

  const saveSettings = async () => {
    if (!companyName) return;
    
    try {
      setSaving(true);
      setError(null);
      setSuccess(false);

      // Update each table's display settings
      for (const table of tables) {
        const tableRef = doc(db, `users/${companyName}/tables`, table.id);
        await setDoc(tableRef, {
          ...table,
          display_columns: displaySettings[table.id]
        }, { merge: true });
      }

      setSuccess(true);
    } catch (err) {
      console.error('Error saving settings:', err);
      setError('Failed to save settings');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="md" sx={{ mt: 8 }}>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Settings
      </Typography>

      <Box sx={{ mb: 4 }}>
        <FormControlLabel
          control={
            <Switch
              checked={showTooltips}
              onChange={(e) => setShowTooltips(e.target.checked)}
              color="primary"
            />
          }
          label="Enable Tooltips"
          labelPlacement="start"
        />
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Settings saved successfully!
        </Alert>
      )}

      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Table Display Settings
      </Typography>

      {tables.map((table) => (
        <Accordion key={table.id} sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{table.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Paper sx={{ p: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Static Columns
              </Typography>
              <FormGroup>
                {table.staticColumns.map((column) => (
                  <FormControlLabel
                    key={column.name}
                    control={
                      <Checkbox
                        checked={displaySettings[table.id]?.[column.name] ?? true}
                        onChange={() => handleColumnToggle(table.id, column.name)}
                      />
                    }
                    label={column.name.replace(/_/g, ' ')}
                  />
                ))}
              </FormGroup>

              {table.metadataColumns.length > 0 && (
                <>
                  <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
                    Metadata Columns
                  </Typography>
                  <FormGroup>
                    {table.metadataColumns.map((column) => (
                      <FormControlLabel
                        key={column.name}
                        control={
                          <Checkbox
                            checked={displaySettings[table.id]?.[column.name] ?? true}
                            onChange={() => handleColumnToggle(table.id, column.name)}
                          />
                        }
                        label={column.name.replace(/_/g, ' ')}
                      />
                    ))}
                  </FormGroup>
                </>
              )}
            </Paper>
          </AccordionDetails>
        </Accordion>
      ))}

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={saveSettings}
          disabled={saving}
        >
          {saving ? 'Saving...' : 'Save Settings'}
        </Button>
      </Box>
    </Container>
  );
}

export default Settings;