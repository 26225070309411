import React, { useState, useEffect } from 'react';
import { db } from '../../services/firebase';
import { collection, getDocs, doc, setDoc, deleteDoc, query } from 'firebase/firestore';
import { 
  TextField, 
  Typography, 
  Box, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle,
  Grid,
  Paper
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import CategoryCard from './CategoryCard';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

function ManageMetadata({ companyName }) {
  const [categories, setCategories] = useState([]);
  const [newCategoryDialog, setNewCategoryDialog] = useState(false);
  const [newCategory, setNewCategory] = useState({ name: '', description: '' });
  const [error, setError] = useState('');
  const [nameError, setNameError] = useState('');

  useEffect(() => {
    loadCategories();
  }, [companyName]);

  const loadCategories = async () => {
    try {
      const categoriesRef = collection(db, `users/${companyName}/tag_categories`);
      const categoriesSnapshot = await getDocs(query(categoriesRef));
      
      const categoriesData = await Promise.all(categoriesSnapshot.docs.map(async (doc) => {
        const valuesRef = collection(db, `users/${companyName}/tag_categories/${doc.id}/values`);
        const valuesSnapshot = await getDocs(query(valuesRef));
        const values = valuesSnapshot.docs.map(valueDoc => ({
          id: valueDoc.id,
          ...valueDoc.data()
        }));

        return {
          id: doc.id,
          ...doc.data(),
          values
        };
      }));

      setCategories(categoriesData);
    } catch (err) {
      setError('Failed to load categories');
      console.error(err);
    }
  };

  const handleAddCategory = async () => {
    try {
      const categoryId = newCategory.name.replace(/\s+/g, '_');
      
      // Check if category already exists
      const categoryRef = doc(db, `users/${companyName}/tag_categories`, categoryId);
      
      await setDoc(categoryRef, {
        name: newCategory.name,
        description: newCategory.description,
        created_at: new Date()
      });

      setNewCategoryDialog(false);
      setNewCategory({ name: '', description: '' });
      await loadCategories();
    } catch (err) {
      setError('Failed to add category');
      console.error(err);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await deleteDoc(doc(db, `users/${companyName}/tag_categories`, categoryId));
      await loadCategories();
    } catch (err) {
      setError('Failed to delete category');
      console.error(err);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6">Manage Metadata</Typography>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => setNewCategoryDialog(true)}
        >
          Add Category
        </Button>
      </Box>

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <Grid container spacing={2}>
        {categories.map((category) => (
          <Grid item xs={12} key={category.id}>
            <CategoryCard
              category={category}
              onDelete={handleDeleteCategory}
              companyName={companyName}
              onReload={loadCategories}
            />
          </Grid>
        ))}
      </Grid>

      <Dialog open={newCategoryDialog} onClose={() => setNewCategoryDialog(false)}>
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            fullWidth
            value={newCategory.name}
            onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={2}
            value={newCategory.description}
            onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewCategoryDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleAddCategory} 
            variant="contained"
            disabled={!newCategory.name.trim()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ManageMetadata;