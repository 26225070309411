import React, { useState, useEffect } from 'react';
import { 
  Box,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Typography,
  Stack,
  Divider
} from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useAuth } from '../../contexts/AuthContext';

function FilterPanel({ onApplyFilters, totalFiles }) {
  const { companyName } = useAuth();
  const [startDate, setStartDate] = useState('2000-01-01');
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [tagCategories, setTagCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState({});
  const [loading, setLoading] = useState(true);

  // Fetch tag categories and their values
  useEffect(() => {
    const fetchTagCategories = async () => {
      if (!companyName) return;

      try {
        const categoriesRef = collection(db, `users/${companyName}/tag_categories`);
        const categoriesSnapshot = await getDocs(categoriesRef);
        
        const categoriesPromises = categoriesSnapshot.docs.map(async (categoryDoc) => {
          const valuesRef = collection(db, `users/${companyName}/tag_categories/${categoryDoc.id}/values`);
          const valuesSnapshot = await getDocs(valuesRef);
          
          // Use document IDs as values
          const values = valuesSnapshot.docs.map(doc => ({
            id: doc.id,  // This is the value itself
            name: doc.id // Display the same value as the name
          }));

          return {
            id: categoryDoc.id,
            name: categoryDoc.id.replace(/_/g, ' '), // Format display name
            values: values
          };
        });

        const categories = await Promise.all(categoriesPromises);
        setTagCategories(categories);
        
        // Initialize selectedTags with empty values for each category
        const initialTags = {};
        categories.forEach(category => {
          initialTags[category.id] = '';
        });
        setSelectedTags(initialTags);
        
      } catch (error) {
        console.error('Error fetching tag categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTagCategories();
  }, [companyName]);

  const handleTagChange = (categoryId, value) => {
    setSelectedTags(prev => ({
      ...prev,
      [categoryId]: value
    }));
  };

  const handleApplyFilters = () => {
    // Remove empty tag selections
    const activeTagFilters = Object.entries(selectedTags)
      .reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});

    onApplyFilters({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      tags: activeTagFilters
    });
  };

  const handleClearFilters = () => {
    setStartDate('2000-01-01');
    setEndDate(new Date().toISOString().split('T')[0]);
    const clearedTags = {};
    tagCategories.forEach(category => {
      clearedTags[category.id] = '';
    });
    setSelectedTags(clearedTags);
    
    onApplyFilters({
      startDate: new Date('2000-01-01'),
      endDate: new Date(),
      tags: {}
    });
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Stack spacing={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" component="div">
            Filters
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {totalFiles} files found
          </Typography>
        </Box>
        
        <Divider />

        <Stack 
          direction={{ xs: 'column', sm: 'row' }} 
          spacing={2} 
          sx={{ width: '100%' }}
        >
          <TextField
            fullWidth
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Stack>

        <Stack 
          direction={{ xs: 'column', sm: 'row' }} 
          spacing={2} 
          sx={{ width: '100%' }}
        >
          {tagCategories.map((category) => (
            <FormControl 
              key={category.id} 
              sx={{ minWidth: 200, flex: 1 }}
            >
              <InputLabel>{category.name}</InputLabel>
              <Select
                value={selectedTags[category.id] || ''}
                label={category.name}
                onChange={(e) => handleTagChange(category.id, e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {category.values.map((value) => (
                  <MenuItem key={value.id} value={value.id}>
                    {value.id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
        </Stack>

        <Stack 
          direction="row" 
          spacing={2} 
          sx={{ justifyContent: 'flex-end' }}
        >
          <Button 
            variant="outlined" 
            onClick={handleClearFilters}
          >
            Clear Filters
          </Button>
          <Button 
            variant="contained" 
            onClick={handleApplyFilters}
          >
            Apply Filters
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

export default FilterPanel;