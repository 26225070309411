import React from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Typography,
  Box 
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderRight: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  '&:last-child': {
    borderRight: 'none',
  },
  backgroundColor: theme.palette.background.paper,
}));

const HeaderCell = styled(StyledTableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  fontWeight: 'bold',
}));

function TableSchemaPreview({ schema }) {
  const isNestedValue = (value) => {
    return typeof value === 'object' && value.type === undefined;
  };

  const processSchema = () => {
    const columns = [];
    const headerRows = [[], [], []]; // Up to 3 rows for headers

    // Add row identifier
    columns.push({
      header: schema.rowIdentifier.name,
      type: schema.rowIdentifier.type,
      isIdentifier: true
    });

    // Add metadata columns
    schema.metadataColumns?.forEach(col => {
      columns.push({
        header: col.name,
        type: col.type,
        isMetadata: true
      });
    });

    // Add static columns
    schema.staticColumns?.forEach(col => {
      columns.push({
        header: col.name,
        type: col.type,
        isStatic: true
      });
    });

    // Process dynamic columns if they exist
    if (schema.dynamicColumns) {
      const dynamicSchema = schema.dynamicColumns.schema;
      let totalColumns = 0;

      // Calculate total columns needed for dynamic part
      Object.entries(dynamicSchema).forEach(([key, value]) => {
        if (isNestedValue(value)) {
          totalColumns += Object.keys(value).length;
        } else {
          totalColumns += 1;
        }
      });

      // Add month header spanning all dynamic columns
      headerRows[0].push({
        content: 'Jan 2024',
        colSpan: totalColumns,
        rowSpan: 1,
        isDynamic: true
      });

      // Process each dynamic column
      Object.entries(dynamicSchema).forEach(([key, value]) => {
        if (isNestedValue(value)) {
          // Nested structure (2 layers)
          headerRows[1].push({
            content: key,
            colSpan: Object.keys(value).length,
            rowSpan: 1
          });

          // Add each nested value
          Object.entries(value).forEach(([nestedKey, nestedValue]) => {
            headerRows[2].push({
              content: nestedKey,
              type: nestedValue.type,
              updateBehavior: nestedValue.updateBehavior,
              colSpan: 1,
              rowSpan: 1
            });
          });
        } else {
          // Single layer
          headerRows[1].push({
            content: key,
            type: value.type,
            updateBehavior: value.updateBehavior,
            colSpan: 1,
            rowSpan: 2
          });
        }
      });
    }

    // Add static columns to all rows for proper alignment
    columns.forEach(col => {
      headerRows[0].unshift({
        content: col.header,
        type: col.type,
        colSpan: 1,
        rowSpan: 3,
        isIdentifier: col.isIdentifier,
        isMetadata: col.isMetadata,
        isStatic: col.isStatic
      });
    });

    return headerRows;
  };

  const headerRows = processSchema();

  const renderHeaderCell = (cell, rowIndex, cellIndex) => {
    if (!cell) return null;

    let subtitle = '';
    let mainContent = cell.content;

    if (cell.type) {
      if (cell.isIdentifier) {
        subtitle = `Row Identifier (${cell.type})`;
      } else if (cell.isMetadata) {
        subtitle = `Metadata (${cell.type})`;
      } else if (cell.isStatic) {
        subtitle = `Static (${cell.type})`;
      } else if (cell.updateBehavior) {
        // For dynamic columns, include type and update behavior in main content
        mainContent = `${cell.content} (${cell.type}, ${cell.updateBehavior})`;
      }
    }

    return (
      <HeaderCell
        key={`${rowIndex}-${cellIndex}`}
        align="center"
        colSpan={cell.colSpan}
        rowSpan={cell.rowSpan}
        sx={{
          backgroundColor: cell.isDynamic ? '#f5f5f5' : undefined,
          minWidth: '120px'
        }}
      >
        <Typography 
          variant="subtitle2" 
          component="div"
          sx={{ 
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {mainContent}
        </Typography>
        {subtitle && (
          <Typography 
            variant="caption" 
            component="div" 
            color="textSecondary"
            sx={{ 
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {subtitle}
          </Typography>
        )}
      </HeaderCell>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          {headerRows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, cellIndex) => renderHeaderCell(cell, rowIndex, cellIndex))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          <TableRow>
            {headerRows[headerRows.length - 1].map((_, index) => (
              <StyledTableCell key={index} align="center">
                <Typography variant="caption" color="textSecondary">
                  Example Data
                </Typography>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableSchemaPreview;