// TransformationBlockConfig.js

import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  TextField, 
  Stack, 
  Divider,
  CircularProgress
} from '@mui/material';
import PreviewTable from './PreviewTable';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { mainAuth, db } from '../../services/firebase';
import { getIdToken } from 'firebase/auth';

import MessageDisplay from '../assistant/MessageDisplay';
import MessageInput from '../assistant/MessageInput';

function TransformationBlockConfig({ 
  initialConfig = {}, 
  onSave, 
  onCancel,
  companyName,
  functionId,
  blockId
}) {
  const [name, setName] = useState(initialConfig.name || '');
  const [displayMessages, setDisplayMessages] = useState([]);
  const [previewData, setPreviewData] = useState(null);
  const [error, setError] = useState('');
  const [finalized, setFinalized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recentCodeSnippet, setRecentCodeSnippet] = useState('');

  const loadTransformationBlock = async () => {
    if (!companyName || !functionId || !blockId) return;
    const docRef = doc(db, `users/${companyName}/data_functions`, functionId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) return;

    const fDoc = docSnap.data();
    const tBlocks = fDoc.transformation_blocks || {};
    const tBlock = tBlocks[blockId];
    if (!tBlock) return;

    setName(tBlock.name || '');
    const dMessages = tBlock.display_messages || [];
    const mappedMessages = dMessages.map(m => ({
      role: m.type === 'assistant' ? 'assistant' : 'user',
      content: m.message
    }));
    setDisplayMessages(mappedMessages);

    let parsedData = [];
    if (tBlock.output_data) {
      try {
        parsedData = JSON.parse(tBlock.output_data);
      } catch (e) {
        console.error('Error parsing output_data:', e);
      }
    }
    setPreviewData(Array.isArray(parsedData) ? parsedData : []);

    setRecentCodeSnippet(tBlock.last_code_snippet || '');
    // Even if finalized, allow editing again. 
    // We'll keep the finalized state for reference but not use it to disable fields.
    if (tBlock.final_code_snippet) setFinalized(true);
  };

  useEffect(() => {
    loadTransformationBlock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, functionId, blockId]);

  const handleSendMessage = async (userMessage) => {
    if (!userMessage.trim()) return;
    setLoading(true);
    setError('');
  
    // Add user message and loading assistant message immediately
    setDisplayMessages(prev => [
      ...prev, 
      { role: 'user', content: userMessage.trim() },
      { role: 'assistant', content: '', loading: true }
    ]);
  
    try {
      const idToken = await getIdToken(mainAuth.currentUser);
      const response = await fetch('https://europe-west2-biminsurance-d5783.cloudfunctions.net/transformation_assistant', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`
        },
        body: JSON.stringify({
          company_id: companyName,
          function_id: functionId,
          block_id: blockId,
          user_message: userMessage.trim()
        })
      });
      if (!response.ok) {
        throw new Error('Failed to process transformation request.');
      }
      
      // Instead of immediately loading from Firestore, update the messages locally first
      setDisplayMessages(prev => {
        const messages = [...prev];
        // Remove the loading message
        messages.pop();
        // Add the actual assistant message
        messages.push({ role: 'assistant', content: 'Processing transformation...', loading: false });
        return messages;
      });
  
      // Then load from Firestore to get the complete updated state
      await loadTransformationBlock();
    } catch (err) {
      console.error('Transform error:', err);
      setError('Error: Could not process transformation.');
      
      // Update messages to show error
      setDisplayMessages(prev => {
        const messages = [...prev];
        // Remove the loading message
        messages.pop();
        // Add error message
        messages.push({ role: 'assistant', content: 'Error: Could not process transformation.', loading: false });
        return messages;
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!companyName || !functionId || !blockId) {
      setError('Missing identifiers to save.');
      return;
    }
    try {
      const docRef = doc(db, `users/${companyName}/data_functions`, functionId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        setError('Function document not found.');
        return;
      }
      const fDoc = docSnap.data();
      const tBlocks = fDoc.transformation_blocks || {};
      if (!tBlocks[blockId]) {
        setError('Transformation block not found in document.');
        return;
      }

      tBlocks[blockId].name = name.trim();
      if (recentCodeSnippet && !tBlocks[blockId].final_code_snippet) {
        tBlocks[blockId].final_code_snippet = recentCodeSnippet;
      }
      tBlocks[blockId].configured = true;

      await updateDoc(docRef, { transformation_blocks: tBlocks });
      setFinalized(true);
      onSave({ name: name.trim() });
    } catch (err) {
      console.error('Save error:', err);
      setError('Failed to save transformation block.');
    }
  };

  return (
    <Box sx={{ p: 2, width: '600px', position:'absolute', top:100, left:100, zIndex:999, background:'#fff', border:'1px solid #ccc', borderRadius:1 }}>
      <Typography variant="h6" gutterBottom>
        Configure Transformation Block
      </Typography>

      {error && (
        <Typography variant="body2" color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <TextField
        fullWidth
        size="small"
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={{ mb:2 }}
        // Removed disabled={finalized} so user can change name even after saving
      />

      <Typography variant="body2" sx={{ mb: 2 }}>
        Chat with the AI to transform your data. The transformations will be reflected below once processed.
      </Typography>

      <Box sx={{ maxHeight: '200px', overflowY: 'auto', mb:2, border:'1px solid #ddd', p:1, borderRadius:1 }}>
        <MessageDisplay messages={displayMessages} loading={loading} />
      </Box>

      {/* Removed disabled={finalized} so user can still send new instructions */}
      <MessageInput onSendMessage={handleSendMessage} isDisabled={loading} />

      <Divider sx={{ my: 2 }} />

      {Array.isArray(previewData) && previewData.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>Transformed Data Preview</Typography>
          <PreviewTable data={previewData} />
        </Box>
      )}

      <Divider sx={{ my: 2 }} />

      <Stack direction="row" spacing={2}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          {finalized ? 'Saved' : 'Save'}
        </Button>
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
      </Stack>
    </Box>
  );
}

export default TransformationBlockConfig;
