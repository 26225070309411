import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Button,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import FormulaDisplay from './FormulaDisplay';

const OPERATORS = ['+', '-', '*', '/', '(', ')'];

const MappingDefinition = ({ table, schema, externalFields = [], initialMapping = {}, initialModes = {}, onChange }) => {
  const [mapping, setMapping] = useState(initialMapping);
  const [mappingModes, setMappingModes] = useState(initialModes);

  const getBaseColumns = (schemaNodes, prefix = '') => {
    const nodes = Array.isArray(schemaNodes) ? schemaNodes : [];
    
    const schemaColumns = nodes.reduce((acc, node) => {
      const fullName = prefix ? `${prefix} > ${node.name}` : node.name;
      if (!node.children || node.children.length === 0) {
        acc.push(fullName);
      } else {
        acc.push(...getBaseColumns(node.children, fullName));
      }
      return acc;
    }, []);

    return schemaColumns;
  };

  const flattenDynamicColumns = (schema) => {
    const flattened = {};
    
    Object.entries(schema).forEach(([key, value]) => {
      if (value.type) {
        // Single level nesting
        flattened[`month.${key}`] = value.type;
      } else {
        // Two level nesting
        Object.entries(value).forEach(([subKey, subValue]) => {
          flattened[`month.${key}.${subKey}`] = subValue.type;
        });
      }
    });
    
    return flattened;
  };

  const externalColumnsArray = Array.isArray(externalFields) ? externalFields : [];
  const externalColumns = externalColumnsArray.map(field => `External > ${field}`);

  const allColumns = {
    schemaColumns: getBaseColumns(schema),
    externalColumns: externalColumns
  };

  const handleMappingChange = (dbColumn, value) => {
    const newMapping = { ...mapping, [dbColumn]: value };
    setMapping(newMapping);
    onChange(newMapping, mappingModes);
  };

  const handleModeChange = (dbColumn, event, newMode) => {
    if (newMode) {
      const newModes = { ...mappingModes, [dbColumn]: newMode };
      setMappingModes(newModes);
      handleMappingChange(dbColumn, '');
      onChange(mapping, newModes);
    }
  };

  const addFormulaElement = (columnName, element, isOperator = false) => {
    const currentFormula = mapping[columnName] || '';
    const newElement = isOperator ? ` \`${element}\` ` : ` ${element} `;
    const newFormula = currentFormula + newElement;
    handleMappingChange(columnName, newFormula.trim());
  };

  const parseFormula = (formula) => {
    if (!formula) return [];
    const regex = /(`[^`]+`)|([^`]+)/g;
    const tokens = formula.match(regex) || [];
    return tokens.map(token => {
      if (token.startsWith('`') && token.endsWith('`')) {
        return { type: 'operator', value: token.slice(1, -1) };
      }
      return { type: 'variable', value: token.trim() };
    });
  };

  const renderColumnMapping = (columnName, columnType) => {
    const isFloat = columnType === 'Number';
    const currentMode = mappingModes[columnName] || 'direct';

    return (
      <Box key={columnName} sx={{ mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              {columnName} ({columnType})
            </Typography>
          </Grid>
          
          {isFloat && (
            <Grid item xs={12}>
              <ToggleButtonGroup
                value={currentMode}
                exclusive
                onChange={(event, newMode) => handleModeChange(columnName, event, newMode)}
                size="small"
              >
                <ToggleButton value="direct">Direct Mapping</ToggleButton>
                <ToggleButton value="formula">Formula</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}

          <Grid item xs={12}>
            {(!isFloat || currentMode === 'direct') ? (
              <FormControl fullWidth>
                <Select
                  value={mapping[columnName] || ''}
                  onChange={(e) => handleMappingChange(columnName, e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="" disabled>Select column</MenuItem>
                  <MenuItem value="__NOT_PRESENT__">Not Present</MenuItem>
                  <Divider />
                  {[
                    ...allColumns.schemaColumns.map((column) => (
                      <MenuItem key={column} value={column}>
                        {column}
                      </MenuItem>
                    )),
                    ...(allColumns.externalColumns.length > 0 ? [
                      <Divider key="divider" />,
                      ...allColumns.externalColumns.map((column) => (
                        <MenuItem key={column} value={column}>
                          {column}
                        </MenuItem>
                      ))
                    ] : [])
                  ]}
                </Select>
              </FormControl>
            ) : (
              <Box>
                <Box sx={{ mb: 1 }}>
                  <FormulaDisplay
                    formulaElements={parseFormula(mapping[columnName])}
                  />
                </Box>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Add Variable</InputLabel>
                      <Select
                        label="Add Variable"
                        onChange={(e) => {
                          if (e.target.value) {
                            addFormulaElement(columnName, e.target.value);
                          }
                        }}
                        value=""
                      >
                        {[
                          ...allColumns.schemaColumns.map((column) => (
                            <MenuItem key={column} value={column}>
                              {column}
                            </MenuItem>
                          )),
                          ...(allColumns.externalColumns.length > 0 ? [
                            <Divider key="divider" />,
                            ...allColumns.externalColumns.map((column) => (
                              <MenuItem key={column} value={column}>
                                {column}
                              </MenuItem>
                            ))
                          ] : [])
                        ]}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                      {OPERATORS.map((op) => (
                        <Button
                          key={op}
                          variant="outlined"
                          size="small"
                          onClick={() => addFormulaElement(columnName, op, true)}
                        >
                          {op}
                        </Button>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderRowIdentifierMapping = () => (
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle1" gutterBottom>
        Row Identifier Mapping
      </Typography>
      {renderColumnMapping(table.rowIdentifier.name, table.rowIdentifier.type)}
    </Box>
  );

  const renderStaticColumnMapping = () => (
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle1" gutterBottom>
        Static Column Mappings
      </Typography>
      {table.staticColumns.map((column) => renderColumnMapping(column.name, column.type))}
    </Box>
  );

  const renderDynamicColumnMapping = () => {
    const flattenedColumns = flattenDynamicColumns(table.dynamicColumns.schema);

    return (
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Dynamic Column Mappings
        </Typography>
        
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Month Column
          </Typography>
          <FormControl fullWidth>
            <Select
              value={mapping['month'] || ''}
              onChange={(e) => handleMappingChange('month', e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>Select month column</MenuItem>
              <MenuItem value="__NOT_PRESENT__">Not Present</MenuItem>
              <Divider />
              {[
                ...allColumns.schemaColumns.map((column) => (
                  <MenuItem key={column} value={column}>
                    {column}
                  </MenuItem>
                )),
                ...(allColumns.externalColumns.length > 0 ? [
                  <Divider key="divider" />,
                  ...allColumns.externalColumns.map((column) => (
                    <MenuItem key={column} value={column}>
                      {column}
                    </MenuItem>
                  ))
                ] : [])
              ]}
            </Select>
          </FormControl>
        </Box>

        <Typography variant="subtitle2" gutterBottom>
          Value Mappings
        </Typography>
        {Object.entries(flattenedColumns).map(([columnName, type]) => 
          renderColumnMapping(columnName, type)
        )}
      </Box>
    );
  };

  return (
    <Box>
      {renderRowIdentifierMapping()}
      <Divider sx={{ my: 3 }} />
      {renderStaticColumnMapping()}
      <Divider sx={{ my: 3 }} />
      {renderDynamicColumnMapping()}
    </Box>
  );
};

export default MappingDefinition;