import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { DashboardProvider } from './contexts/DashboardContext';
import Header from './components/common/Header';
import DataView from './pages/DataView';
import ManageData from './pages/ManageData';
import Settings from './pages/Settings';
import Dashboard from './pages/Dashboard';
import theme from './theme';
import { TextFormatterProvider } from './components/utils/TextFormatterProvider';
function PrivateRoute({ children }) {
  const { currentUser, companyName } = useAuth();

  if (currentUser === null) {
    return <div>Loading...</div>;
  }

  return currentUser && companyName ? (
    <>
      <Header />
      {children}
    </>
  ) : (
    <div>Please sign in to access this page.</div>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <TextFormatterProvider>
        <CssBaseline />
        <AuthProvider>
          <DashboardProvider>
            <Router>
              <Routes>
                <Route path="/manage-data" element={<PrivateRoute><ManageData /></PrivateRoute>} />
                <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
                <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/" element={<PrivateRoute><DataView /></PrivateRoute>} />
              </Routes>
            </Router>
          </DashboardProvider>
        </AuthProvider>
      </TextFormatterProvider>
    </ThemeProvider>
  );
}

export default App;