// VisualizationBlockConfig.js

import React, { useState, useEffect, useMemo } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Stack, 
  Divider,
  TextField,
  CircularProgress
} from '@mui/material';
import PreviewTable from './PreviewTable';
import MessageDisplay from '../assistant/MessageDisplay';
import MessageInput from '../assistant/MessageInput';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { mainAuth, db } from '../../services/firebase';
import { getIdToken } from 'firebase/auth';

function VisualizationBlockConfig({
  initialConfig = {},
  dataSample = [],
  onSave,
  onCancel,
  companyName,
  functionId,
  blockId
}) {
  const [name, setName] = useState(initialConfig.name || '');
  const [type, setType] = useState(initialConfig.type || 'table');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // For AI mode (bar/line):
  const [displayMessages, setDisplayMessages] = useState([]);
  const [finalized, setFinalized] = useState(false);
  const [recentCodeSnippet, setRecentCodeSnippet] = useState('');
  const [charts, setCharts] = useState([]);

  // Load block data from Firestore
  const loadVisualizationBlock = async () => {
    if (!companyName || !functionId || !blockId) return;
    const docRef = doc(db, `users/${companyName}/data_functions`, functionId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) return;

    const fDoc = docSnap.data();
    const vBlocks = fDoc.visualization_blocks || {};
    const vBlock = vBlocks[blockId];
    if (!vBlock) return;

    setName(vBlock.name || '');
    const dMessages = vBlock.display_messages || [];
    // Reconstruct messages array with roles and charts
    const reconstructedMessages = dMessages.map((m) => {
      if (m.type === 'user') {
        return { role: 'user', content: m.message };
      } else if (m.type === 'assistant') {
        // assistant message may have charts
        const assistantCharts = vBlock.charts || [];
        // The charts are stored at block level, we assume last assistant message references them
        // For simplicity, attach charts to the last assistant message:
        return { role: 'assistant', content: m.message, charts: assistantCharts };
      }
      return { role: 'assistant', content: '' };
    });

    setDisplayMessages(reconstructedMessages);
    setRecentCodeSnippet(vBlock.last_code_snippet || '');
    if (vBlock.final_code_snippet) setFinalized(true);
    // If no charts field or empty, no charts currently shown.
    setCharts(vBlock.charts || []);
  };

  useEffect(() => {
    loadVisualizationBlock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, functionId, blockId]);

  const needsAI = useMemo(() => type === 'bar_chart' || type === 'line_chart', [type]);
  const chart_type_for_api = useMemo(() => {
    if (type === 'bar_chart') return 'bar';
    if (type === 'line_chart') return 'line';
    return null;
  }, [type]);
  const handleSendMessage = async (userMessage) => {
    if (!userMessage.trim()) return;
    if (!companyName || !functionId || !blockId) {
      setError('Missing identifiers to send message.');
      return;
    }
    if (!chart_type_for_api) {
      setError('Cannot send message without a valid chart type.');
      return;
    }

    setLoading(true);
    setError('');

    // Add user message optimistically
    setDisplayMessages(prev => [...prev, { role: 'user', content: userMessage }, { role: 'assistant', content: '', loading: true }]);

    try {
      const idToken = await getIdToken(mainAuth.currentUser);
      const response = await fetch('https://europe-west2-biminsurance-d5783.cloudfunctions.net/visualisation_assistant', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`
        },
        body: JSON.stringify({
          company_id: companyName,
          function_id: functionId,
          block_id: blockId,
          user_message: userMessage,
          chart_type: chart_type_for_api
        })
      });

      if (!response.ok) {
        throw new Error('Failed to process visualisation request.');
      }

      const data = await response.json();
      const assistantReply = data.response || 'Error: No response from assistant.';
      let assistantCharts = data.charts || [];

      // Replace the loading assistant message with the actual reply
      setDisplayMessages(prev => {
        const updatedMessages = [...prev];
        const lastIndex = updatedMessages.length - 1;
        if (updatedMessages[lastIndex].role === 'assistant' && updatedMessages[lastIndex].loading) {
          updatedMessages[lastIndex] = {
            role: 'assistant',
            content: assistantReply,
            charts: assistantCharts,
            loading: false
          };
        }
        return updatedMessages;
      });

      setCharts(assistantCharts);

    } catch (err) {
      console.error('Visualisation transform error:', err);
      setError('Error: Could not process visualisation request.');
      // Replace last assistant loading message with error
      setDisplayMessages(prev => {
        const updated = [...prev];
        const lastIndex = updated.length - 1;
        if (lastIndex >= 0 && updated[lastIndex].role === 'assistant' && updated[lastIndex].loading) {
          updated[lastIndex] = { role: 'assistant', content: 'Error: Could not get a response.', loading: false };
        }
        return updated;
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!companyName || !functionId || !blockId) {
      setError('Missing identifiers to save.');
      return;
    }
  
    try {
      const docRef = doc(db, `users/${companyName}/data_functions`, functionId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        setError('Function document not found.');
        return;
      }
  
      // Get current visualization block data
      const fDoc = docSnap.data();
      const visualization_blocks = fDoc.visualization_blocks || {};
      const currentBlockData = visualization_blocks[blockId] || {};
  
      // Update only specific fields while preserving existing data
      await updateDoc(docRef, {
        [`visualization_blocks.${blockId}`]: {
          ...currentBlockData,
          configured: true,
          name: name.trim(),
          type: type,
        }
      });
  
      setFinalized(true);
      onSave({ name: name.trim(), type });
    } catch (err) {
      console.error('Save error:', err);
      setError('Failed to save visualization block.');
    }
  };

  return (
    <Box sx={{ p: 2, width: '600px', position:'absolute', top:100, left:100, zIndex:999, background:'#fff', border:'1px solid #ccc', borderRadius:1 }}>
      <Typography variant="h6" gutterBottom>
        Configure Visualization Block
      </Typography>

      {error && (
        <Typography variant="body2" color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <TextField
        fullWidth
        size="small"
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={{ mb:2 }}
        disabled={loading}
      />

      <Typography variant="body2" sx={{ mb: 2 }}>
        Select a visualization type. For tables, no AI required. For bar/line charts, instruct the AI.
      </Typography>

      <FormControl fullWidth size="small" sx={{ mb: 2 }} disabled={loading || finalized}>
        <InputLabel>Type</InputLabel>
        <Select value={type} label="Type" onChange={(e) => setType(e.target.value)}>
          <MenuItem value="table">Table</MenuItem>
          <MenuItem value="bar_chart">Bar Chart</MenuItem>
          <MenuItem value="line_chart">Line Chart</MenuItem>
        </Select>
      </FormControl>

      <Divider sx={{ my: 2 }} />

      {type === 'table' && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>Table Preview</Typography>
          {Array.isArray(dataSample) && dataSample.length > 0 ? (
            <PreviewTable data={dataSample} />
          ) : (
            <Typography variant="body2" color="text.secondary">
              No data available to configure visualization.
            </Typography>
          )}
        </Box>
      )}

      {needsAI && (
        <>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Interact with the AI to configure your {type.replace('_',' ')} visualization.
          </Typography>

          <Box sx={{ maxHeight: '200px', overflowY: 'auto', mb:2, border:'1px solid #ddd', p:1, borderRadius:1 }}>
            <MessageDisplay messages={displayMessages} loading={loading} />
          </Box>
          <MessageInput onSendMessage={handleSendMessage} isDisabled={loading} />
        </>
      )}

      <Divider sx={{ my: 2 }} />

      <Stack direction="row" spacing={2}>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={loading}>
          {finalized ? 'Saved' : 'Save'}
        </Button>
        <Button variant="text" onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
      </Stack>
    </Box>
  );
}

export default VisualizationBlockConfig;
