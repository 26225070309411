// BarChart.js
import React from 'react';
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts';

function BarChartComponent({ chartData }) {
  const data = chartData.elements.map(el => ({
    label: el.label,
    value: el.value,
  }));

  return (
    <div style={{ width: '100%', height: 350, marginBottom: 20}}>
      <h3>{chartData.title}</h3>
      <ResponsiveContainer>
        <RechartsBarChart data={data} margin={{ top: 10, right: 5, left:20, bottom: 10 }} >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="label"
            label={{
              value: chartData.x_label,
              position: 'insideBottom',
              offset: -10,
            }}
          />
          <YAxis
            label={{
              value: chartData.y_label,
              angle: -90,
              position: 'insideLeft',
              dx: -10,
              dy: 100
            }}
          />
          <Tooltip />
          <Legend 
            verticalAlign="bottom" 
            margin={{ top: 20 }}
            wrapperStyle={{ paddingTop: '20px' }}
          />
          <Bar dataKey="value" fill="#8884d8" name={chartData.y_label} />
        </RechartsBarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default BarChartComponent;
