import React, { useState, useEffect } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { collection, query, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import MappingCard from './MappingCard';
import MappingEditor from './MappingEditor';

const MappingsManager = ({ companyId, tables }) => {
  const [activeTable, setActiveTable] = useState(0);
  const [mappings, setMappings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editingMapping, setEditingMapping] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  useEffect(() => {
    fetchMappings();
  }, [companyId]);

  const fetchMappings = async () => {
    try {
      setLoading(true);
      setError('');

      const mappingsRef = collection(db, `users/${companyId}/mappings`);
      const mappingsSnapshot = await getDocs(mappingsRef);
      
      const fetchedMappings = mappingsSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));

      setMappings(fetchedMappings);
    } catch (err) {
      console.error('Error fetching mappings:', err);
      setError('Failed to load mappings');
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTable(newValue);
  };

  const handleCreateMapping = () => {
    setIsCreating(true);
  };

  const handleEditMapping = (mapping) => {
    setEditingMapping(mapping);
  };

  const handleDeleteMapping = (mapping) => {
    setDeleteConfirmation(mapping);
  };

  const confirmDelete = async () => {
    if (!deleteConfirmation) return;

    try {
      setLoading(true);
      const mappingRef = doc(db, `users/${companyId}/mappings/${deleteConfirmation.id}`);
      await deleteDoc(mappingRef);
      
      setMappings(prevMappings => 
        prevMappings.filter(m => m.id !== deleteConfirmation.id)
      );
      setDeleteConfirmation(null);
    } catch (err) {
      console.error('Error deleting mapping:', err);
      setError('Failed to delete mapping');
    } finally {
      setLoading(false);
    }
  };

  const handleMappingEditorClose = (savedMapping) => {
    if (savedMapping) {
      setMappings(prevMappings => {
        const index = prevMappings.findIndex(m => m.id === savedMapping.id);
        if (index !== -1) {
          // Update existing mapping
          return prevMappings.map(m => 
            m.id === savedMapping.id ? savedMapping : m
          );
        } else {
          return [...prevMappings, savedMapping];
        }
      });
    }
    setIsCreating(false);
    setEditingMapping(null);
  };

  const getCurrentTable = () => {
    return tables[activeTable];
  };

  const getTableMappings = () => {
    const currentTable = getCurrentTable();
    return mappings.filter(mapping => mapping.table === currentTable.name);
  };

  if (loading && mappings.length === 0) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Manage Mappings
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Tabs
        value={activeTable}
        onChange={handleTabChange}
        sx={{ mb: 3 }}
      >
        {tables.map((table) => (
          <Tab key={table.name} label={table.name} />
        ))}
      </Tabs>

      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleCreateMapping}
        >
          Create New Mapping
        </Button>
      </Box>

      {getTableMappings().map((mapping) => (
        <MappingCard
          key={mapping.id}
          mapping={mapping}
          onEdit={handleEditMapping}
          onDelete={handleDeleteMapping}
        />
      ))}

      {(isCreating || editingMapping) && (
        <MappingEditor
          open={true}
          onClose={handleMappingEditorClose}
          table={getCurrentTable()}
          initialMapping={editingMapping}
          companyId={companyId}
          availableMappings={getTableMappings()}
        />
      )}

      <Dialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the mapping "{deleteConfirmation?.id}"?
          This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation(null)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MappingsManager;