import React from 'react';
import { 
  Grid, 
  Paper, 
  Box, 
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FileStatusCards = ({ recentFiles, historicalFiles }) => {
  const getStatusIcon = (status) => {
    switch (status) {
      case 'Completed':
        return <CheckCircleIcon color="success" sx={{ mr: 1, mt: 0.5 }} />;
      case 'Failed':
        return <CancelIcon color="error" sx={{ mr: 1, mt: 0.5 }} />;
      case 'Pending':
      case 'Processing':
        return <CircularProgress size={20} sx={{ mr: 1, mt: 0.5 }} />;
      default:
        return null;
    }
  };

  const FileGrid = ({ files }) => (
    <Box sx={{ 
      maxHeight: '400px',
      overflowY: 'auto',
      pr: 1
    }}>
      <Grid container spacing={2}>
        {files.map((file) => (
          <Grid item xs={12} sm={6} md={4} key={file.id}>
            <Paper sx={{ 
              p: 2, 
              display: 'flex', 
              alignItems: 'flex-start', 
              height: '100%',
              backgroundColor: file.status === 'Failed' ? 'error.lighter' : 'background.paper'
            }}>
              {getStatusIcon(file.status)}
              <Box sx={{ flexGrow: 1, minWidth: 0 }}>
                <Typography variant="subtitle1" noWrap={false} sx={{ wordBreak: 'break-word' }}>
                  {file.fileName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Status: {file.status}
                </Typography>
                {file.status === 'Failed' && file.error && (
                  <Typography 
                    variant="body2" 
                    color="error" 
                    noWrap={false} 
                    sx={{ wordBreak: 'break-word' }}
                  >
                    Error: {file.error}
                  </Typography>
                )}
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  if ((!recentFiles || recentFiles.length === 0) && (!historicalFiles || historicalFiles.length === 0)) {
    return null;
  }

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      {recentFiles && recentFiles.length > 0 && (
        <Box>
          <Typography variant="h6" gutterBottom>
            Recent Files Status (Last 24 Hours)
          </Typography>
          <FileGrid files={recentFiles} />
        </Box>
      )}
      
      {historicalFiles && historicalFiles.length > 0 && (
        <Accordion sx={{ mt: 3 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="upload-history-content"
            id="upload-history-header"
          >
            <Typography variant="h6">Upload History</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ 
            p: 0,
            '& > div': { maxHeight: '400px' }
          }}>
            <FileGrid files={historicalFiles} />
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};

export default FileStatusCards;