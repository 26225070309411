import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Paper,
  Button,
  Switch,
  FormControlLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

const dataTypes = ['Text', 'Number', 'Date', 'Yes/No'];

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 500,
}));

function ColumnDefinition({ rowIdentifier, metadataColumns, staticColumns, onChange }) {
  const handleRowIdentifierChange = (field) => (event) => {
    onChange('rowIdentifier', {
      ...rowIdentifier,
      [field]: event.target.value
    });
  };

  const handleColumnChange = (type, index, field) => (event) => {
    const columns = type === 'metadataColumns' ? [...metadataColumns] : [...staticColumns];
    columns[index] = {
      ...columns[index],
      [field]: event.target.value
    };
    onChange(type, columns);
  };

  const handleMandatoryChange = (type, index) => (event) => {
    const columns = type === 'metadataColumns' ? [...metadataColumns] : [...staticColumns];
    columns[index] = {
      ...columns[index],
      mandatory: event.target.checked
    };
    onChange(type, columns);
  };

  const handleAddColumn = (type) => {
    const newColumn = {
      name: '',
      type: 'Text',
      description: '',
      mandatory: false
    };
    const columns = type === 'metadataColumns' ? [...metadataColumns] : [...staticColumns];
    onChange(type, [...columns, newColumn]);
  };

  const handleDeleteColumn = (type, index) => {
    const columns = type === 'metadataColumns' ? [...metadataColumns] : [...staticColumns];
    columns.splice(index, 1);
    onChange(type, columns);
  };

  const renderColumnFields = (column, index, type) => (
    <Grid container spacing={2} alignItems="center" key={index}>
      <Grid item xs={5}>
        <TextField
          fullWidth
          label="Column Name"
          value={column.name}
          onChange={handleColumnChange(type, index, 'name')}
          size="small"
          required
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Description"
          value={column.description || ''}
          onChange={handleColumnChange(type, index, 'description')}
          size="small"
        />
      </Grid>
      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Switch
              checked={column.mandatory || false}
              onChange={handleMandatoryChange(type, index)}
              size="small"
            />
          }
          label="Mandatory"
          labelPlacement="start"
          sx={{ marginLeft: 0 }}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton 
          onClick={() => handleDeleteColumn(type, index)}
          size="small"
          color="error"
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ p: 2 }}>
      {/* Row Identifier */}
      <StyledPaper>
        <SectionTitle variant="h6">
          Row Identifier Column
        </SectionTitle>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Column Name"
              value={rowIdentifier.name}
              onChange={handleRowIdentifierChange('name')}
              size="small"
              required
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Type</InputLabel>
              <Select
                value={rowIdentifier.type}
                onChange={handleRowIdentifierChange('type')}
                label="Type"
              >
                {dataTypes.map(type => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </StyledPaper>

      {/* Metadata Columns */}
      <StyledPaper>
        <SectionTitle variant="h6">
          Metadata Columns
        </SectionTitle>
        <Box sx={{ mb: 2 }}>
          {metadataColumns.map((column, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              {renderColumnFields(column, index, 'metadataColumns')}
            </Box>
          ))}
        </Box>
        <Button
          startIcon={<AddIcon />}
          onClick={() => handleAddColumn('metadataColumns')}
          variant="outlined"
          size="small"
        >
          Add Metadata Column
        </Button>
      </StyledPaper>

      {/* Static Columns */}
      <StyledPaper>
        <SectionTitle variant="h6">
          Static Columns
        </SectionTitle>
        <Box sx={{ mb: 2 }}>
          {staticColumns.map((column, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              {renderColumnFields(column, index, 'staticColumns')}
            </Box>
          ))}
        </Box>
        <Button
          startIcon={<AddIcon />}
          onClick={() => handleAddColumn('staticColumns')}
          variant="outlined"
          size="small"
        >
          Add Static Column
        </Button>
      </StyledPaper>
    </Box>
  );
}

export default ColumnDefinition;