// MessageDisplay.js
import React, { useRef, useEffect } from 'react';
import { List, ListItem, ListItemText, Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import BarChartComponent from './BarChart';
import LineChartComponent from './LineChart';

const MessageList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const AssistantMessage = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  alignSelf: 'flex-start',
  maxWidth: '90%',
  padding: theme.spacing(1),
}));

const UserMessage = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  alignSelf: 'flex-end',
  maxWidth: '70%',
  padding: theme.spacing(1),
}));

function MessageDisplay({ messages, loading }) {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <MessageList>
      {messages.map((message, index) => (
        <ListItemStyled
          key={index}
          style={{ alignItems: message.role === 'user' ? 'flex-end' : 'flex-start' }}
        >
          {message.role === 'user' ? (
            <UserMessage>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    {message.content}
                  </Typography>
                }
              />
            </UserMessage>
          ) : (
            <AssistantMessage>
              {message.loading ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  <ListItemText
                    primary={
                      <Typography variant="body1">
                        {message.content}
                      </Typography>
                    }
                  />
                  {message.charts && message.charts.map((chartData, chartIndex) => (
                    <div key={chartIndex} style={{ marginTop: '16px' }}>
                      {chartData.type === 'bar' && (
                        <BarChartComponent chartData={chartData} />
                      )}
                      {chartData.type === 'line' && (
                        <LineChartComponent chartData={chartData} />
                      )}
                    </div>
                  ))}
                </>
              )}
            </AssistantMessage>
          )}
        </ListItemStyled>
      ))}
      <div ref={messagesEndRef} />
    </MessageList>
  );
}

export default MessageDisplay;
