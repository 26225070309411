import React, { useState, useCallback, memo } from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Grid,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

const dataTypes = ['Text', 'Number', 'Date', 'Yes/No'];
const updateBehaviors = ['Add', 'Subtract', 'Overwrite'];

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const NestedBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  paddingLeft: theme.spacing(2),
  borderLeft: `2px solid ${theme.palette.divider}`,
}));

const EditableField = memo(({ initialValue, onSave, label, fullWidth = true, size = "small" }) => {
  const [editingValue, setEditingValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    setEditingValue(e.target.value);
  };

  const handleBlur = () => {
    if (editingValue !== initialValue) {
      onSave(editingValue);
    }
    setIsEditing(false);
  };

  const handleFocus = () => {
    setEditingValue(initialValue);
    setIsEditing(true);
  };

  return (
    <TextField
      fullWidth={fullWidth}
      label={label}
      value={isEditing ? editingValue : initialValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      size={size}
    />
  );
});

function DynamicColumnBuilder({ value, onChange }) {
  const [enabled, setEnabled] = useState(!!value);

  const updateSchema = useCallback((newSchema) => {
    onChange({
      type: 'Month',
      schema: newSchema
    });
  }, [onChange]);

  const addValue = useCallback(() => {
    if (!value?.schema) return;
    const newSchema = { ...value.schema };
    const newKey = `value${Object.keys(newSchema).length + 1}`;
    newSchema[newKey] = {
      type: 'Number',
      updateBehavior: 'Overwrite',
      mandatory: false
    };
    updateSchema(newSchema);
  }, [value?.schema, updateSchema]);

  const addGroup = useCallback(() => {
    if (!value?.schema) return;
    const newSchema = { ...value.schema };
    const newKey = `group${Object.keys(newSchema).length + 1}`;
    newSchema[newKey] = {};
    updateSchema(newSchema);
  }, [value?.schema, updateSchema]);

  const addNestedValue = useCallback((groupKey) => {
    if (!value?.schema) return;
    const newSchema = { ...value.schema };
    const newKey = `value${Object.keys(newSchema[groupKey]).length + 1}`;
    newSchema[groupKey] = {
      ...newSchema[groupKey],
      [newKey]: {
        type: 'Number',
        updateBehavior: 'Overwrite',
        mandatory: false
      }
    };
    updateSchema(newSchema);
  }, [value?.schema, updateSchema]);

  const deleteItem = useCallback((key) => {
    if (!value?.schema) return;
    const newSchema = { ...value.schema };
    delete newSchema[key];
    updateSchema(newSchema);
  }, [value?.schema, updateSchema]);

  const deleteNestedItem = useCallback((groupKey, valueKey) => {
    if (!value?.schema) return;
    const newSchema = { ...value.schema };
    const newGroup = { ...newSchema[groupKey] };
    delete newGroup[valueKey];
    newSchema[groupKey] = newGroup;
    updateSchema(newSchema);
  }, [value?.schema, updateSchema]);

  const updateItemName = useCallback((oldKey, newName) => {
    if (!value?.schema || !newName || newName === oldKey) return;
    const newSchema = { ...value.schema };
    const itemValue = newSchema[oldKey];
    delete newSchema[oldKey];
    newSchema[newName] = itemValue;
    updateSchema(newSchema);
  }, [value?.schema, updateSchema]);

  const updateNestedItemName = useCallback((groupKey, oldKey, newName) => {
    if (!value?.schema || !newName || newName === oldKey) return;
    const newSchema = { ...value.schema };
    const newGroup = { ...newSchema[groupKey] };
    const itemValue = newGroup[oldKey];
    delete newGroup[oldKey];
    newGroup[newName] = itemValue;
    newSchema[groupKey] = newGroup;
    updateSchema(newSchema);
  }, [value?.schema, updateSchema]);

  const updateItemProperty = useCallback((key, property, newValue) => {
    if (!value?.schema) return;
    const newSchema = { ...value.schema };
    newSchema[key] = {
      ...newSchema[key],
      [property]: newValue
    };
    updateSchema(newSchema);
  }, [value?.schema, updateSchema]);

  const updateNestedItemProperty = useCallback((groupKey, valueKey, property, newValue) => {
    if (!value?.schema) return;
    const newSchema = { ...value.schema };
    newSchema[groupKey] = {
      ...newSchema[groupKey],
      [valueKey]: {
        ...newSchema[groupKey][valueKey],
        [property]: newValue
      }
    };
    updateSchema(newSchema);
  }, [value?.schema, updateSchema]);

  const handleEnableSwitch = useCallback((e) => {
    setEnabled(e.target.checked);
    if (e.target.checked) {
      onChange({ type: 'Month', schema: {} });
    } else {
      onChange(null);
    }
  }, [onChange]);

  if (!enabled) {
    return (
      <Box sx={{ p: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={enabled}
              onChange={handleEnableSwitch}
            />
          }
          label="Enable Dynamic Columns"
        />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <FormControlLabel
        control={
          <Switch
            checked={enabled}
            onChange={handleEnableSwitch}
          />
        }
        label="Enable Dynamic Columns"
      />

      <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
        Monthly Column Structure
      </Typography>

      {Object.entries(value?.schema || {}).map(([key, schemaValue]) => (
        <StyledPaper key={key}>
          {typeof schemaValue === 'object' && !schemaValue.type ? (
            // Group with nested values
            <div>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={11}>
                  <EditableField
                    initialValue={key}
                    onSave={(newName) => updateItemName(key, newName)}
                    label="Group Name"
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => deleteItem(key)} size="small" color="error">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <NestedBox>
                {Object.entries(schemaValue).map(([nestedKey, nestedValue]) => (
                  <Box key={`${key}-${nestedKey}`} sx={{ mt: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3}>
                        <EditableField
                          initialValue={nestedKey}
                          onSave={(newName) => updateNestedItemName(key, nestedKey, newName)}
                          label="Value Name"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl fullWidth size="small">
                          <InputLabel>Type</InputLabel>
                          <Select
                            value={nestedValue.type}
                            onChange={(e) => updateNestedItemProperty(key, nestedKey, 'type', e.target.value)}
                            label="Type"
                          >
                            {dataTypes.map(type => (
                              <MenuItem key={type} value={type}>{type}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl fullWidth size="small">
                          <InputLabel>Update Behavior</InputLabel>
                          <Select
                            value={nestedValue.updateBehavior}
                            onChange={(e) => updateNestedItemProperty(key, nestedKey, 'updateBehavior', e.target.value)}
                            label="Update Behavior"
                          >
                            {updateBehaviors.map(behavior => (
                              <MenuItem key={behavior} value={behavior}>{behavior}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={nestedValue.mandatory || false}
                              onChange={(e) => updateNestedItemProperty(key, nestedKey, 'mandatory', e.target.checked)}
                              size="small"
                            />
                          }
                          label="Mandatory"
                          labelPlacement="start"
                          sx={{ marginLeft: 0 }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton onClick={() => deleteNestedItem(key, nestedKey)} size="small" color="error">
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => addNestedValue(key)}
                  variant="outlined"
                  size="small"
                  sx={{ mt: 2 }}
                >
                  Add Value
                </Button>
              </NestedBox>
            </div>
          ) : (
            // Single value
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <EditableField
                  initialValue={key}
                  onSave={(newName) => updateItemName(key, newName)}
                  label="Value Name"
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={schemaValue.type}
                    onChange={(e) => updateItemProperty(key, 'type', e.target.value)}
                    label="Type"
                  >
                    {dataTypes.map(type => (
                      <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>Update Behavior</InputLabel>
                  <Select
                    value={schemaValue.updateBehavior}
                    onChange={(e) => updateItemProperty(key, 'updateBehavior', e.target.value)}
                    label="Update Behavior"
                  >
                    {updateBehaviors.map(behavior => (
                      <MenuItem key={behavior} value={behavior}>{behavior}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={schemaValue.mandatory || false}
                      onChange={(e) => updateItemProperty(key, 'mandatory', e.target.checked)}
                      size="small"
                    />
                  }
                  label="Mandatory"
                  labelPlacement="start"
                  sx={{ marginLeft: 0 }}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => deleteItem(key)} size="small" color="error">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </StyledPaper>
      ))}

      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          startIcon={<AddIcon />}
          onClick={addValue}
          variant="outlined"
          size="small"
        >
          Add Single Value
        </Button>
        <Button
          startIcon={<AddIcon />}
          onClick={addGroup}
          variant="outlined"
          size="small"
        >
          Add Group
        </Button>
      </Box>
    </Box>
  );
}

export default memo(DynamicColumnBuilder);