// ChatHistory.js
import React from 'react';
import { List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@mui/material';

function ChatHistory({ assistantList, onSelectAssistant, onClose }) {
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Chat History</DialogTitle>
      <DialogContent dividers>
        {assistantList.length === 0 ? (
          <Typography variant="body1">No previous chats found.</Typography>
        ) : (
          <List>
            {assistantList.map((assistantId) => (
              <ListItem button key={assistantId} onClick={() => onSelectAssistant(assistantId)}>
                <ListItemText primary={assistantId} />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChatHistory;
