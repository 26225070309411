import React, { useState, useCallback, useEffect } from 'react';
import { TextField, Button, IconButton, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomTooltip from '../common/CustomTooltip';
import SchemaPreview from './SchemaPreview';

const SchemaNode = ({ node, onUpdate, onDelete, depth = 0 }) => {
  const handleNameChange = (e) => {
    onUpdate({ ...node, name: e.target.value });
  };

  const handleAddChild = () => {
    const newChild = { name: '', children: [] };
    onUpdate({ ...node, children: [...node.children, newChild] });
  };

  const handleUpdateChild = (index, updatedChild) => {
    const updatedChildren = node.children.map((child, i) =>
      i === index ? updatedChild : child
    );
    onUpdate({ ...node, children: updatedChildren });
  };

  const handleDeleteChild = (index) => {
    const updatedChildren = node.children.filter((_, i) => i !== index);
    onUpdate({ ...node, children: updatedChildren });
  };

  return (
    <Box sx={{ ml: depth * 3, mb: 1, borderLeft: depth > 0 ? '1px solid #ccc' : 'none', pl: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          value={node.name}
          onChange={handleNameChange}
          placeholder="None"
          size="small"
          sx={{ mr: 1 }}
        />
        <IconButton onClick={handleAddChild} size="small">
          <AddIcon />
        </IconButton>
        <IconButton onClick={() => onDelete()} size="small">
          <DeleteIcon />
        </IconButton>
      </Box>
      {node.children.map((child, index) => (
        <SchemaNode
          key={index}
          node={child}
          onUpdate={(updatedChild) => handleUpdateChild(index, updatedChild)}
          onDelete={() => handleDeleteChild(index)}
          depth={depth + 1}
        />
      ))}
    </Box>
  );
};

const SchemaBuilder = ({ initialSchema, onSchemaChange }) => {
  const [schema, setSchema] = useState(initialSchema || []);

  useEffect(() => {
    setSchema(initialSchema || []);
  }, [initialSchema]);

  const handleSchemaUpdate = useCallback(
    (updatedSchema) => {
      setSchema(updatedSchema);
      onSchemaChange(updatedSchema);
    },
    [onSchemaChange]
  );

  const handleAddTopLevelColumn = () => {
    const newColumn = { name: '', children: [] };
    handleSchemaUpdate([...schema, newColumn]);
  };

  const handleUpdateColumn = (index, updatedColumn) => {
    const updatedSchema = schema.map((column, i) =>
      i === index ? updatedColumn : column
    );
    handleSchemaUpdate(updatedSchema);
  };

  const handleDeleteColumn = (index) => {
    const updatedSchema = schema.filter((_, i) => i !== index);
    handleSchemaUpdate(updatedSchema);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Bordereau Spreadsheet Format
        <CustomTooltip
          title="Define the Incoming Spreadsheet Format"
          content="Here you need to define the columns of the Bordereau you will upload. You can add multiple layers of columns (e.g. columns with parent-child relationships) which allow us to extract complex spreadsheets. Note that base level columns must be at the same depth in the hierarchy - so if a base level column has no parent, add the necessary number of parents with the 'None' value."
        />
      </Typography>
      {schema.map((column, index) => (
        <SchemaNode
          key={index}
          node={column}
          onUpdate={(updatedColumn) => handleUpdateColumn(index, updatedColumn)}
          onDelete={() => handleDeleteColumn(index)}
          depth={0}
        />
      ))}
      <Button
        startIcon={<AddIcon />}
        onClick={handleAddTopLevelColumn}
        variant="outlined"
        sx={{ mt: 1, mb: 2 }}
      >
        Add Top Level Column
      </Button>
      <SchemaPreview schema={schema} />
    </Box>
  );
};

export default SchemaBuilder;