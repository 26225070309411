import React, { useState } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton, 
  Collapse,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SchemaPreview from './SchemaPreview';

const MappingCard = ({ mapping, onEdit, onDelete }) => {
  const [expanded, setExpanded] = useState(false);

  const renderMappingPreview = () => {
    const sections = {
      'Static/Metadata Fields': Object.entries(mapping.mapping)
        .filter(([key]) => !key.includes('.'))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
      'Monthly Fields': Object.entries(mapping.mapping)
        .filter(([key]) => key.includes('.'))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    };
  
    return (
      <Box>
        {Object.entries(sections).map(([title, content]) => (
          <Box key={title} sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>{title}</Typography>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '50%' }}>Database Column</TableCell>
                    <TableCell sx={{ width: '50%' }}>Schema Column</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(content).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell sx={{ width: '50%' }}>{key}</TableCell>
                      <TableCell sx={{ width: '50%' }}>{value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
      </Box>
    );
  };

  const renderDefaultTags = () => {
    if (!mapping.default_tags || Object.keys(mapping.default_tags).length === 0) {
      return (
        <Typography variant="body2" color="text.secondary">
          No default tags defined
        </Typography>
      );
    }

    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '50%' }}>Category</TableCell>
              <TableCell sx={{ width: '50%' }}>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(mapping.default_tags).map(([category, value]) => (
              <TableRow key={category}>
                <TableCell sx={{ width: '50%' }}>{category}</TableCell>
                <TableCell sx={{ width: '50%' }}>{value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Paper sx={{ mb: 2, overflow: 'hidden' }}>
      <Box sx={{ p: 2 }}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography variant="h6">{mapping.id}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => onEdit(mapping)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => onDelete(mapping)}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={() => setExpanded(!expanded)}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Collapse in={expanded}>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle1" gutterBottom>Schema</Typography>
          <SchemaPreview schema={mapping.schema} />
          
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            External Data Fields
          </Typography>
          <Box sx={{ pl: 2, mb: 2 }}>
            {mapping.externalFields?.map((field, index) => (
              <Typography key={index} variant="body2">
                {field}
              </Typography>
            )) || <Typography variant="body2">No external fields defined</Typography>}
          </Box>

          <Typography variant="subtitle1" gutterBottom>
            Column Mappings
          </Typography>
          {renderMappingPreview()}

          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            Default Tags
          </Typography>
          {renderDefaultTags()}
        </Box>
      </Collapse>
    </Paper>
  );
};

export default MappingCard;