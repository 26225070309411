import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Button,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const ExternalDataFields = ({ fields = [], onChange }) => {  // Add default empty array
  const handleAddField = () => {
    onChange([...fields, '']);
  };

  const handleDeleteField = (index) => {
    const newFields = Array.from(fields).filter((_, i) => i !== index);  // Ensure array
    onChange(newFields);
  };

  const handleFieldChange = (index, value) => {
    const newFields = Array.from(fields);  // Ensure array
    newFields[index] = value;
    onChange(newFields);
  };

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom>
        External Data Fields
      </Typography>
      <List dense>
        {(Array.isArray(fields) ? fields : []).map((field, index) => (  // Ensure array when mapping
          <ListItem
            key={index}
            secondaryAction={
              <IconButton 
                edge="end" 
                aria-label="delete"
                onClick={() => handleDeleteField(index)}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <TextField
              fullWidth
              size="small"
              value={field}
              onChange={(e) => handleFieldChange(index, e.target.value)}
              placeholder="Enter field name"
              sx={{ mr: 2 }}
            />
          </ListItem>
        ))}
      </List>
      <Button
        startIcon={<AddIcon />}
        onClick={handleAddField}
        variant="outlined"
        size="small"
        sx={{ mt: 1 }}
      >
        Add External Field
      </Button>
    </Box>
  );
};

export default ExternalDataFields;