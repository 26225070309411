// RetrievalBlockConfig.js

import React, { useState, useEffect, useMemo } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  TextField, 
  IconButton, 
  Checkbox, 
  FormControlLabel, 
  Select, 
  MenuItem, 
  Stack, 
  Divider,
  FormControl,
  InputLabel
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PreviewTable from './PreviewTable';
import { db } from '../../services/firebase';
import { doc, getDoc } from 'firebase/firestore';

function RetrievalBlockConfig({ 
  initialConfig = {}, 
  tables = [], 
  companyName,
  onSave, 
  onCancel, 
  onPreviewRequest
}) {
  const [name, setName] = useState(initialConfig.name || '');
  const [tableId, setTableId] = useState(initialConfig.table_id || '');
  const [filters, setFilters] = useState(initialConfig.filters || []);
  const [previewData, setPreviewData] = useState(null);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [error, setError] = useState('');

  const [staticColumns, setStaticColumns] = useState([]);
  const [metadataColumns, setMetadataColumns] = useState([]);

  // Track if preview is fresh. If user changes any filter/table after preview, it's stale
  const [previewFresh, setPreviewFresh] = useState(false);

  useEffect(() => {
    // If filters or table changes after preview, preview is no longer fresh
    setPreviewFresh(false);
    setPreviewData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableId]);

  const handleFiltersChange = () => {
    setPreviewFresh(false);
    setPreviewData(null);
  };

  useEffect(() => {
    if (!companyName || !tableId) return;
    (async () => {
      const docRef = doc(db, `users/${companyName}/tables`, tableId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const tDoc = docSnap.data();
        setStaticColumns(tDoc.staticColumns || []);
        setMetadataColumns(tDoc.metadataColumns || []);
      } else {
        setStaticColumns([]);
        setMetadataColumns([]);
      }
    })();
  }, [companyName, tableId]);

  const handleAddFilter = () => {
    setFilters(prev => [...prev, {
      column: '',
      value: [''],
      variable: false
    }]);
    handleFiltersChange();
  };

  const handleRemoveFilter = (index) => {
    setFilters(prev => prev.filter((_, i) => i !== index));
    handleFiltersChange();
  };

  const handleFilterChange = (index, key, value) => {
    setFilters(prev => {
      const newFilters = [...prev];
      newFilters[index] = { ...newFilters[index], [key]: value };
      return newFilters;
    });
    handleFiltersChange();
  };

  const handleFilterValueChange = (index, valueIndex, newValue) => {
    setFilters(prev => {
      const newFilters = [...prev];
      const f = newFilters[index];
      const vals = [...f.value];
      vals[valueIndex] = newValue;
      f.value = vals;
      newFilters[index] = f;
      return newFilters;
    });
    handleFiltersChange();
  };

  const handleAddFilterValue = (index) => {
    setFilters(prev => {
      const newFilters = [...prev];
      const f = newFilters[index];
      f.value = [...f.value, ''];
      newFilters[index] = f;
      return newFilters;
    });
    handleFiltersChange();
  };

  const handleRemoveFilterValue = (index, valueIndex) => {
    setFilters(prev => {
      const newFilters = [...prev];
      const f = newFilters[index];
      f.value = f.value.filter((_, i) => i !== valueIndex);
      newFilters[index] = f;
      return newFilters;
    });
    handleFiltersChange();
  };

  // Possible columns + "Start Date" and "End Date"
  const allColumns = useMemo(() => {
    const cols = [...staticColumns.map(c => c.name), ...metadataColumns.map(c => c.name)];
    return [...new Set([...cols, "Start Date", "End Date"])];
  }, [staticColumns, metadataColumns]);

  const handlePreview = async () => {
    if (!tableId) {
      setError('Please select a table.');
      return;
    }
    setError('');
    setPreviewData(null);
    setLoadingPreview(true);
    try {
      const filterObj = {};
      let startDateFilter = '';
      let endDateFilter = '';

      for (const f of filters) {
        if (!f.column) continue;
        const trimmedValues = f.value.map(v => v.trim());
        // For non-variable filters, we must have at least one non-empty value to use it.
        // For variable filters, it's okay if we have no defaults.
        if (f.column === "Start Date") {
          // For preview, if no value is given, default to '2000-01-01'
          const val = trimmedValues.find(v => v !== '') || '';
          startDateFilter = val || '2000-01-01';
        } else if (f.column === "End Date") {
          // For preview, if no value is given, default to today
          const val = trimmedValues.find(v => v !== '') || '';
          endDateFilter = val || new Date().toISOString().split('T')[0];
        } else {
          // Normal filter
          // If variable and no values, just don't filter by this column for preview
          // If non-variable and no values, skip this filter.
          const nonEmptyValues = trimmedValues.filter(v => v !== '');
          if (f.variable) {
            // Variable filter: if user provided a default, use it now. If not, skip for preview.
            if (nonEmptyValues.length > 0) {
              filterObj[f.column] = nonEmptyValues;
            }
          } else {
            // Non-variable: must have at least one non-empty value to apply now.
            if (nonEmptyValues.length > 0) {
              filterObj[f.column] = nonEmptyValues;
            }
          }
        }
      }

      const data = await onPreviewRequest(tableId, filterObj, startDateFilter || '2000-01-01', endDateFilter || new Date().toISOString().split('T')[0]);
      setPreviewData(data);
      setPreviewFresh(true);
    } catch (err) {
      console.error('Preview error:', err);
      setError('Failed to fetch preview data.');
    } finally {
      setLoadingPreview(false);
    }
  };

  const handleSave = () => {
    if (!tableId) {
      setError('Please select a table before saving.');
      return;
    }
    if (!previewFresh || !previewData) {
      setError('You must run a preview after your last changes before saving.');
      return;
    }

    // Construct final filters for saving to Firestore
    // - If variable = false, we must have at least one non-empty value, otherwise filter is omitted.
    // - If variable = true, we can save the filter even if no default values provided (save empty array).
    const finalFilters = filters.map(f => {
      const trimmedValues = f.value.map(v => v.trim());
      if (!f.column) {
        return null;
      }
      if (f.variable) {
        // Variable filter: we can store empty arrays or arrays of values as is
        // Remove empty strings, but if all are empty, store empty array
        const values = trimmedValues.filter(v => v !== '');
        return {
          column: f.column,
          value: values, // can be empty
          variable: true
        };
      } else {
        // Non-variable: require at least one non-empty value
        const nonEmptyValues = trimmedValues.filter(v => v !== '');
        if (nonEmptyValues.length === 0) {
          // no valid values, skip this filter
          return null;
        }
        return {
          column: f.column,
          value: nonEmptyValues,
          variable: false
        };
      }
    }).filter(ff => ff !== null);

    const config = {
      name: name.trim(),
      table_id: tableId,
      filters: finalFilters,
      preview_data: previewData
    };
    onSave(config);
  };

  return (
    <Box sx={{ p: 2, width: '600px', position:'absolute', top:100, left:100, zIndex:999, background:'#fff', border:'1px solid #ccc', borderRadius:1 }}>
      <Typography variant="h6" gutterBottom>
        Configure Retrieval Block
      </Typography>

      {error && (
        <Typography variant="body2" color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <TextField
        fullWidth
        size="small"
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={{ mb:2 }}
      />

      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1">Select Table</Typography>
        <FormControl fullWidth size="small" sx={{ mt:1 }}>
          <InputLabel>Select a table...</InputLabel>
          <Select
            value={tableId}
            label="Select a table..."
            onChange={(e) => setTableId(e.target.value)}
          >
            <MenuItem value="">
              <em>Select a table...</em>
            </MenuItem>
            {tables.map(t => (
              <MenuItem key={t.id} value={t.id}>
                {t.name || t.id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Filters (= only)
      </Typography>
      {allColumns.length === 0 && (
        <Typography variant="body2" color="text.secondary">
          Select a table to load columns.
        </Typography>
      )}
      <Stack spacing={2}>
        {filters.map((f, index) => (
          <Box key={index} sx={{ border: '1px solid #ddd', p: 2, borderRadius: 1 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
              <Typography variant="body1">Filter {index + 1}</Typography>
              <IconButton size="small" color="error" onClick={() => handleRemoveFilter(index)}>
                <RemoveIcon />
              </IconButton>
            </Stack>

            <FormControl fullWidth size="small" sx={{ mb: 1 }}>
              <InputLabel>Column</InputLabel>
              <Select
                value={f.column}
                label="Column"
                onChange={(e) => handleFilterChange(index, 'column', e.target.value)}
              >
                <MenuItem value="">
                  <em>Select a column...</em>
                </MenuItem>
                {allColumns.map(col => (
                  <MenuItem key={col} value={col}>{col}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography variant="body2">Values (optional if variable)</Typography>
            {f.value.map((val, valIndex) => (
              <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1 }} key={valIndex}>
                <TextField
                  size="small"
                  value={val}
                  onChange={(e) => handleFilterValueChange(index, valIndex, e.target.value)}
                />
                <IconButton size="small" onClick={() => handleRemoveFilterValue(index, valIndex)} color="error">
                  <RemoveIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            ))}
            <Button
              variant="text"
              size="small"
              startIcon={<AddIcon />}
              sx={{ mt: 1 }}
              onClick={() => handleAddFilterValue(index)}
              disabled={!f.column}
            >
              Add Value
            </Button>

            <Divider sx={{ my: 2 }} />

            <FormControlLabel
              control={
                <Checkbox
                  checked={f.variable}
                  onChange={(e) => handleFilterChange(index, 'variable', e.target.checked)}
                  disabled={!f.column}
                />
              }
              label="Make this filter variable"
            />
          </Box>
        ))}
      </Stack>

      <Button
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        sx={{ mt: 2 }}
        onClick={handleAddFilter}
        disabled={allColumns.length === 0}
      >
        Add Filter
      </Button>

      <Divider sx={{ my: 2 }} />

      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Button variant="contained" onClick={handlePreview} disabled={loadingPreview || !tableId}>Preview</Button>
        {loadingPreview && <Typography variant="body2">Loading preview...</Typography>}
      </Stack>

      {previewData && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Preview Data
          </Typography>
          <PreviewTable data={previewData} />
        </Box>
      )}

      <Divider sx={{ my: 2 }} />

      <Stack direction="row" spacing={2}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
      </Stack>
    </Box>
  );
}

export default RetrievalBlockConfig;
