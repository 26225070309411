import React, { useState } from 'react';
import { 
  Paper, 
  Typography, 
  Box, 
  IconButton, 
  Button, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  Divider
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { db } from '../../services/firebase';
import { doc, setDoc, deleteDoc } from 'firebase/firestore';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  position: 'relative',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function CategoryCard({ category, onDelete, companyName, onReload }) {
  const [expanded, setExpanded] = useState(false);
  const [newValueDialog, setNewValueDialog] = useState(false);
  const [newValue, setNewValue] = useState('');
  const [error, setError] = useState('');

  const handleAddValue = async () => {
    try {
      const valueDocRef = doc(db, `users/${companyName}/tag_categories/${category.id}/values`, newValue);
      await setDoc(valueDocRef, {
        value: newValue,
        created_at: new Date()
      });
      
      setNewValueDialog(false);
      setNewValue('');
      onReload();
    } catch (err) {
      setError('Failed to add value');
      console.error(err);
    }
  };

  const handleDeleteValue = async (valueId) => {
    try {
      await deleteDoc(
        doc(db, `users/${companyName}/tag_categories/${category.id}/values`, valueId)
      );
      onReload();
    } catch (err) {
      setError('Failed to delete value');
      console.error(err);
    }
  };

  return (
    <StyledPaper elevation={2}>
      {error && (
        <Typography color="error" sx={{ mb: 1 }}>
          {error}
        </Typography>
      )}
      
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant="h6">{category.name}</Typography>
          <Typography variant="body2" color="text.secondary">
            {category.description}
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={() => onDelete(category.id)} size="small">
            <DeleteIcon />
          </IconButton>
          <IconButton onClick={() => setExpanded(!expanded)} size="small">
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </Box>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Typography variant="subtitle1">Values</Typography>
            <Button
              startIcon={<AddIcon />}
              size="small"
              onClick={() => setNewValueDialog(true)}
            >
              Add Value
            </Button>
          </Box>
          <List dense>
            {category.values.map((value) => (
              <StyledListItem key={value.id}>
                <ListItemText primary={value.value} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={() => handleDeleteValue(value.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </StyledListItem>
            ))}
          </List>
        </Box>
      </Collapse>

      <Dialog open={newValueDialog} onClose={() => setNewValueDialog(false)}>
        <DialogTitle>Add New Value</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Value"
            fullWidth
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewValueDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleAddValue} 
            variant="contained"
            disabled={!newValue.trim()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </StyledPaper>
  );
}

export default CategoryCard;