import React, { useEffect, useState } from 'react';
import { 
  Container, 
  Grid, 
  Paper, 
  Button, 
  Typography, 
  TextField, 
  Box, 
  IconButton, 
  List, 
  ListItem, 
  ListItemText,
  Divider,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  CircularProgress,
  Stack
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { db } from '../services/firebase';
import { collection, doc, getDocs, setDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import FunctionBuilderDialog from '../components/functions/FunctionBuilderDialog';
import PreviewTable from '../components/functions/PreviewTable';
import BarChartComponent from '../components/assistant/BarChart';
import LineChartComponent from '../components/assistant/LineChart';
import { getIdToken } from 'firebase/auth';
import { mainAuth } from '../services/firebase';

// Utility function to run the function endpoint
async function runFunctionEndpoint(functionId, parameters) {
  const idToken = await getIdToken(mainAuth.currentUser);
  const response = await fetch('https://europe-west2-biminsurance-d5783.cloudfunctions.net/run_data_function', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`
    },
    body: JSON.stringify(parameters)
  });
  if (!response.ok) {
    const text = await response.text();
    throw new Error(text);
  }
  const json = await response.json();
  return json;
}

async function getTablesFromFirestore(companyName) {
  const tablesRef = collection(db, `users/${companyName}/tables`);
  const snapshot = await getDocs(tablesRef);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

function Dashboard() {
  const { companyName } = useAuth();
  const [functions, setFunctions] = useState([]);
  const [draftFunctions, setDraftFunctions] = useState([]);
  const [finalFunctions, setFinalFunctions] = useState([]);
  const [predefinedFunctions, setPredefinedFunctions] = useState([]); // New state for predefined functions
  const [customFunctions, setCustomFunctions] = useState([]); // New state for custom functions
  const [tables, setTables] = useState([]);
  const [selectedFunctionId, setSelectedFunctionId] = useState(null);
  const [functionDoc, setFunctionDoc] = useState(null);
  const [showBuilder, setShowBuilder] = useState(false);
  const [parametersFormData, setParametersFormData] = useState({});
  const [runResult, setRunResult] = useState(null);
  const [runError, setRunError] = useState('');
  const [deleteConfirmId, setDeleteConfirmId] = useState(null);
  const [nameDialogOpen, setNameDialogOpen] = useState(false);
  const [functionToName, setFunctionToName] = useState(null);
  const [newFunctionName, setNewFunctionName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Load functions and tables
  useEffect(() => {
    if (!companyName) return;
    (async () => {
      const funcsRef = collection(db, `users/${companyName}/data_functions`);
      const funcsSnap = await getDocs(funcsRef);
      const funcs = funcsSnap.docs.map(doc => ({id: doc.id, ...doc.data()}));
      setFunctions(funcs);

      const drafts = funcs.filter(f => f.status === 'Draft');
      const finals = funcs.filter(f => f.status === 'Final');
      setDraftFunctions(drafts);
      setFinalFunctions(finals);

      // Split functions into predefined and custom
      const predefined = finals.filter(f => f.author !== 'user');
      const custom = finals.filter(f => f.author === 'user');
      setPredefinedFunctions(predefined);
      setCustomFunctions(custom);

      const tbls = await getTablesFromFirestore(companyName);
      setTables(tbls);
    })();
  }, [companyName]);

  const refreshFunctions = async () => {
    if (!companyName) return;
    const funcsRef = collection(db, `users/${companyName}/data_functions`);
    const funcsSnap = await getDocs(funcsRef);
    const funcs = funcsSnap.docs.map(doc => ({id: doc.id, ...doc.data()}));
    setFunctions(funcs);
    const drafts = funcs.filter(f => f.status === 'Draft');
    const finals = funcs.filter(f => f.status === 'Final');
    setDraftFunctions(drafts);
    setFinalFunctions(finals);

    // Update predefined and custom functions
    const predefined = finals.filter(f => f.author !== 'user');
    const custom = finals.filter(f => f.author === 'user');
    setPredefinedFunctions(predefined);
    setCustomFunctions(custom);
  };

  const handleCreateFunction = async () => {
    if (!companyName) return;
    const id = `${Date.now()}_${Math.floor(Math.random()*1000)}`;

    const newFunctionData = {
      status: 'Draft',
      author: 'user',
      nodes: [],
      edges: [],
      retrieval_blocks: {},
      transformation_blocks: {},
      visualization_blocks: {}
    };

    await setDoc(doc(db, `users/${companyName}/data_functions`, id), newFunctionData);

    await refreshFunctions();

    setSelectedFunctionId(id);
    setRunResult(null);
    const docRef = doc(db, `users/${companyName}/data_functions`, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const fDoc = docSnap.data();
      setFunctionDoc(fDoc);
    } else {
      setFunctionDoc(null);
    }
    setParametersFormData({});
    setShowBuilder(true);
  };

  const handleSelectFunction = async (funcId) => {
    setSelectedFunctionId(funcId);
    setRunResult(null);
    setRunError('');
    if (!companyName || !funcId) {
      setFunctionDoc(null);
      return;
    }
    const docRef = doc(db, `users/${companyName}/data_functions`, funcId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const fDoc = docSnap.data();
      setFunctionDoc(fDoc);

      if (fDoc.status === 'Final') {
        const newFormData = {};
        Object.entries(fDoc.retrieval_blocks || {}).forEach(([blockId, block]) => {
          const blockData = {};
          (block.filters || []).forEach(filter => {
            const valArr = Array.isArray(filter.value) ? filter.value : [];
            const valStr = valArr.join(', ');
            blockData[filter.column] = valStr;
          });
          newFormData[blockId] = {
            name: block.name || blockId,
            data: blockData
          };
        });
        setParametersFormData(newFormData);
      } else {
        setParametersFormData({});
      }
    } else {
      setFunctionDoc(null);
      setParametersFormData({});
    }
  };

  const handleEditFunction = async (funcId) => {
    // Check if function is editable
    const func = functions.find(f => f.id === funcId);
    if (!func || func.author !== 'user') {
      return; // Don't open builder for predefined functions
    }

    // First load the function doc if not already loaded
    if (selectedFunctionId !== funcId) {
      setSelectedFunctionId(funcId);
      const docRef = doc(db, `users/${companyName}/data_functions`, funcId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const fDoc = docSnap.data();
        setFunctionDoc(fDoc);
      }
    }
    setShowBuilder(true);
  };

  const handleDeleteFunction = async () => {
    if (!deleteConfirmId || !companyName) {
      setDeleteConfirmId(null);
      return;
    }

    // Check if function is deletable
    const func = functions.find(f => f.id === deleteConfirmId);
    if (!func || func.author !== 'user') {
      setDeleteConfirmId(null);
      return;
    }

    await deleteDoc(doc(db, `users/${companyName}/data_functions`, deleteConfirmId));
    setDeleteConfirmId(null);
    await refreshFunctions();
    if (selectedFunctionId === deleteConfirmId) {
      setSelectedFunctionId(null);
      setFunctionDoc(null);
      setRunResult(null);
      setParametersFormData({});
    }
  };

  const handleOpenNameDialog = (func) => {
    setFunctionToName(func);
    setNewFunctionName(func.name || '');
    setNameDialogOpen(true);
  };

  const handleNameSave = async () => {
    if (!companyName || !functionToName || !newFunctionName.trim()) return;
    
    const docRef = doc(db, `users/${companyName}/data_functions`, functionToName.id);
    await setDoc(docRef, { 
        ...functionToName, 
        name: newFunctionName.trim(),
        status: 'Final'
    }, { merge: true });
    
    setNameDialogOpen(false);
    setFunctionToName(null);
    setNewFunctionName('');
    setShowBuilder(false);
    await refreshFunctions();
  };

  const handleParameterChange = (blockId, column, newValue) => {
    setParametersFormData(prev => {
      const copy = {...prev};
      if (!copy[blockId]) return prev;
      copy[blockId] = {...copy[blockId], data: {...copy[blockId].data, [column]: newValue}};
      return copy;
    });
  };

  const handleRunFunction = async () => {
    if (!selectedFunctionId || !functionDoc) return;
    setRunError('');
    setRunResult(null);
    setIsLoading(true);
    const payload = { function_id: selectedFunctionId };
    for (const [blockId, blockObj] of Object.entries(parametersFormData)) {
      const blockDataObj = {};
      for (const [col, valStr] of Object.entries(blockObj.data)) {
        const vals = valStr.split(',').map(v => v.trim()).filter(v => v !== '');
        if (col === "Start Date" || col === "End Date") {
          blockDataObj[col] = vals.length > 0 ? vals[0] : "";
        } else {
          blockDataObj[col] = vals;
        }
      }
      payload[blockId] = blockDataObj;
    }

    try {
      const result = await runFunctionEndpoint(selectedFunctionId, payload);
      let data;

      if (typeof result.data === 'string') {
        data = JSON.parse(result.data);
      } else {
        data = result.data;
      }
      if (!Array.isArray(data) || data.length === 0) {
        setRunResult({ type: 'table', data: [] });
      } else {
        const firstItem = data[0];
          if (firstItem.type === 'bar') {
            setRunResult({ type: 'bar_chart', data: data });
          } else if (firstItem.type === 'line') {
            setRunResult({ type: 'line_chart', data: data });
        } else {
          setRunResult({ type: 'table', data: data });
        }
      }
    } catch (err) {
      console.error(err);
      setRunError(`Failed to run function: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const isDateColumn = (col) => (col === "Start Date" || col === "End Date");

  return (
    <Container maxWidth={false} sx={{ height: '100vh', pt: 2, pb: 4 }}>
      <Grid container spacing={3} sx={{ height: 'calc(100% - 64px)' }}>
        {/* Sidebar */}
        <Grid item xs={12} md={3} lg={2}>
          <Paper sx={{ p:2, height: '100%' }}>
            <Box sx={{ mb:2, display:'flex', justifyContent:'space-between', alignItems:'center' }}>
              <Typography variant="h6">Functions</Typography>
              <IconButton color="primary" onClick={handleCreateFunction}>
                <AddIcon />
              </IconButton>
            </Box>
            
            {/* Custom Functions */}
            <Typography variant="subtitle2" sx={{ mb: 1 }}>Custom</Typography>
            <List>
              {customFunctions.map(f => (
                <ListItem 
                  key={f.id}
                  selected={f.id === selectedFunctionId}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <ListItemText 
                    primary={f.name || f.id} 
                    onClick={() => handleSelectFunction(f.id)}
                    sx={{ cursor: 'pointer' }}
                  />
                  <Box>
                    <IconButton size="small" onClick={() => handleEditFunction(f.id)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small" onClick={() => setDeleteConfirmId(f.id)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </ListItem>
              ))}
            </List>

            {/* Predefined Functions */}
            <Divider sx={{my:2}} />
            <Typography variant="subtitle2" sx={{ mb: 1 }}>Predefined</Typography>
            <List>
              {predefinedFunctions.map(f => (
                <ListItem 
                  key={f.id}
                  selected={f.id === selectedFunctionId}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <ListItemText 
                    primary={f.name || f.id} 
                    onClick={() => handleSelectFunction(f.id)}
                    sx={{ cursor: 'pointer' }}
                  />
                  <Box>
                    <IconButton size="small" disabled>
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small" disabled>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </ListItem>
              ))}
            </List>

            <Divider sx={{my:2}} />
            <Typography variant="subtitle2">Drafts</Typography>
            <List>
              {draftFunctions.map(f => (
                <ListItem 
                  key={f.id}
                  selected={f.id === selectedFunctionId}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <ListItemText 
                    primary={f.name || f.id}
                    onClick={() => handleSelectFunction(f.id)}
                    sx={{ cursor: 'pointer' }}
                  />
                  <Box>
                    <IconButton size="small" onClick={() => handleEditFunction(f.id)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small" onClick={() => setDeleteConfirmId(f.id)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Rest of the component remains unchanged */}
        {/* Main Content */}
        <Grid item xs={12} md={9} lg={10}>
          <Paper sx={{ p:2, display: 'flex', flexDirection:'column', height: '85vh', overflow:'auto' }}>
            {selectedFunctionId && functionDoc && (
              <>
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mb:2 }}>
                  <Typography 
                    variant="h4" 
                    align="center"
                    sx={{ 
                      fontWeight: 500,
                      py: 2
                    }}
                  >
                    {functionDoc.name || selectedFunctionId}
                  </Typography>
                </Stack>

                {runError && (
                  <Alert severity="error" sx={{ mb:2 }}>{runError}</Alert>
                )}

                {/* Output Section */}
                <Box sx={{ mb:2, flexGrow: 1 }}>
                  {isLoading ? (
                    <Box sx={{ 
                      height: '200px', 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center',
                      bgcolor: 'grey.100',
                      borderRadius: 1
                    }}>
                      <CircularProgress />
                    </Box>
                  ) : runResult ? (
                    <>
                      {runResult.type === 'table' && (
                        <PreviewTable data={runResult.data} />
                      )}
                      {runResult.type === 'bar_chart' && runResult.data.map((chartData, idx) => (
                        <Box key={idx} sx={{mb:2}}>
                          <BarChartComponent chartData={chartData} />
                        </Box>
                      ))}
                      {runResult.type === 'line_chart' && runResult.data.map((chartData, idx) => (
                        <Box key={idx} sx={{mb:2}}>
                          <LineChartComponent chartData={chartData} />
                        </Box>
                      ))}
                    </>
                  ) : (
                    <Box sx={{ 
                      height: '200px', 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center',
                      bgcolor: 'grey.100',
                      borderRadius: 1
                    }}>
                      <Typography variant="body1" color="text.secondary">
                        Output will be here
                      </Typography>
                    </Box>
                  )}
                </Box>

                {/* Parameters Form */}
                <Box sx={{ mt: 'auto' }}>
                  <Typography variant="subtitle1" sx={{ mb:1 }}>Parameters</Typography>
                  {Object.entries(parametersFormData).map(([blockId, blockObj]) => (
                    <Box key={blockId} sx={{ border: '1px solid #ddd', p:2, borderRadius:1, mb:2 }}>
                      <Typography variant="h6" sx={{ mb:2 }}>
                        {blockObj.name}
                      </Typography>
                      {Object.entries(blockObj.data).map(([col, valStr]) => {
                        const blockFilters = functionDoc.retrieval_blocks[blockId].filters || [];
                        const thisFilter = blockFilters.find(f => f.column === col);
                        const isVariable = thisFilter ? thisFilter.variable : false;
                        const disabled = !isVariable;
                        return (
                          <Box key={col} sx={{mb:2}}>
                            <Typography variant="body2" sx={{mb:1}}>{col}</Typography>
                            <TextField
                              type={isDateColumn(col) ? "date" : "text"}
                              fullWidth
                              size="small"
                              value={valStr}
                              onChange={(e) => handleParameterChange(blockId, col, e.target.value)}
                              disabled={disabled}
                              InputLabelProps={isDateColumn(col) ? { shrink: true } : {}}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  ))}
                  <Button variant="contained" color="success" onClick={handleRunFunction}>
                    Run
                  </Button>
                </Box>
              </>
            )}
            {!selectedFunctionId && (
              <Typography variant="body2" color="text.secondary">
                Select a function from the sidebar or create a new one.
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      {functionDoc && showBuilder && (
        <FunctionBuilderDialog
          open={showBuilder}
          onClose={() => setShowBuilder(false)}
          onSave={handleOpenNameDialog}
          tables={tables} 
          companyName={companyName}
          functionId={selectedFunctionId}
        />
      )}

      <Dialog open={Boolean(deleteConfirmId)} onClose={() => setDeleteConfirmId(null)}>
        <DialogTitle>Delete Function</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this function?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmId(null)}>Cancel</Button>
          <Button onClick={handleDeleteFunction} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={nameDialogOpen} onClose={() => setNameDialogOpen(false)}>
        <DialogTitle>Name Function</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Function Name"
            fullWidth
            value={newFunctionName}
            onChange={(e) => setNewFunctionName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNameDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleNameSave} disabled={!newFunctionName.trim()}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Dashboard;