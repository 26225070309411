import React, { createContext, useState, useEffect, useContext } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { useAuth } from './AuthContext';
import { db } from '../services/firebase';

const DashboardContext = createContext();

export function useDashboard() {
  return useContext(DashboardContext);
}

export function DashboardProvider({ children }) {
  // Table Management
  const [tables, setTables] = useState([]);
  const [currentTableId, setCurrentTableId] = useState(null);
  const [tableData, setTableData] = useState({});

  // Date Range
  const [startDate, setStartDate] = useState('2000-01-01');
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  
  // Filter State
  const [filterState, setFilterState] = useState({});
  
  // UI State
  const [showTooltips, setShowTooltips] = useState(true);
  
  const { companyName } = useAuth();

  // Fetch tag categories for metadata columns
  useEffect(() => {
    const fetchTagCategories = async () => {
      if (!companyName) return;

      try {
        const categoriesCollection = collection(db, `users/${companyName}/tag_categories`);
        const categoriesSnapshot = await getDocs(categoriesCollection);
        
        const newFilterState = { ...filterState };
        let hasNewFilters = false;

        // Initialize filter state for tag categories
        for (const doc of categoriesSnapshot.docs) {
          const categoryId = doc.id;
          if (!filterState.hasOwnProperty(categoryId)) {
            newFilterState[categoryId] = [];
            hasNewFilters = true;
          }
        }

        if (hasNewFilters) {
          setFilterState(newFilterState);
        }
      } catch (error) {
        console.error('Error fetching tag categories:', error);
      }
    };

    fetchTagCategories();
  }, [companyName]);

  // Clear table data when company changes
  useEffect(() => {
    if (!companyName) {
      setTableData({});
      setCurrentTableId(null);
    }
  }, [companyName]);

  const clearTableData = (tableId) => {
    setTableData(prev => {
      const newData = { ...prev };
      delete newData[tableId];
      return newData;
    });
  };

  const clearAllTableData = () => {
    setTableData({});
  };

  const updateFilterState = (newFilters) => {
    setFilterState(prev => ({
      ...prev,
      ...newFilters
    }));
  };

  const value = {
    // Table Management
    tables,
    setTables,
    currentTableId,
    setCurrentTableId,
    tableData,
    setTableData,
    clearTableData,
    clearAllTableData,

    // Date Range
    startDate,
    setStartDate,
    endDate,
    setEndDate,

    // Filter State
    filterState,
    setFilterState,
    updateFilterState,

    // UI State
    showTooltips,
    setShowTooltips,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
}