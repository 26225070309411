// LineChart.js
import React from 'react';
import {
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts';

function LineChartComponent({ chartData }) {
  // Extract all unique x-values from the chart data
  const xValuesSet = new Set();
  chartData.elements.forEach(line => {
    line.points.forEach(point => {
      xValuesSet.add(point[0]);
    });
  });
  const xValues = Array.from(xValuesSet);

  // Build the data array for Recharts
  const data = xValues.map(xValue => {
    const dataPoint = { xValue };
    chartData.elements.forEach(line => {
      const point = line.points.find(p => p[0] === xValue);
      dataPoint[line.label] = point ? point[1] : null;
    });
    return dataPoint;
  });

  return (
    <div style={{ width: '100%', height: 350, padding: '20px 10px'}}>
      <h3>{chartData.title}</h3>
      <ResponsiveContainer>
        <RechartsLineChart data={data}  margin={{ top: 10, right: 5, left:15, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="xValue"
            label={{
              value: chartData.x_label,
              position: 'insideBottom',
              offset: -5,
            }}
          />
          <YAxis
            label={{
              value: chartData.y_label,
              angle: -90,
              position: 'insideLeft',
              dx: -10,
              dy: 100
            }}
          />
          <Tooltip />
          <Legend />
          {chartData.elements.map((line, index) => (
            <Line
              key={index}
              type="monotone"
              dataKey={line.label}
              stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
              name={line.label}
            />
          ))}
        </RechartsLineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default LineChartComponent;
