import React from 'react';
import ReactFlow, { MiniMap, Controls, Background, Handle, Position } from 'reactflow';
import 'reactflow/dist/style.css';
import { Box, Button, Typography, Stack, Paper } from '@mui/material';

function RetrievalNode({ data }) {
  return (
    <Paper elevation={3} sx={{
      p:1,
      border: '1px solid #ccc',
      minWidth: 150,
      backgroundColor: '#e1f5e1',
      borderRadius: 2
    }}>
      <Typography variant="body2" align="center" sx={{ fontWeight: 'bold' }}>
        {data.config?.name || 'Retrieval Node'}
      </Typography>
      <Typography variant="caption" display="block" align="center">
        (Retrieval)
      </Typography>
      <Stack direction="row" justifyContent="space-between" sx={{ mt:1 }}>
        <Handle type="source" position={Position.Right} isConnectable={true} />
        <Button size="small" onClick={() => data.onConfigure(data.id)}>Configure</Button>
      </Stack>
    </Paper>
  );
}

function TransformationNode({ data }) {
  return (
    <Paper elevation={3} sx={{
      p:1,
      border: '1px solid #ccc',
      minWidth: 150,
      backgroundColor: '#e1e7f5',
      borderRadius: '50%'
    }}>
      <Typography variant="body2" align="center" sx={{ fontWeight: 'bold' }}>
        {data.config?.name || 'Transformation Node'}
      </Typography>
      <Typography variant="caption" display="block" align="center">
        (Transformation)
      </Typography>
      <Stack direction="row" justifyContent="space-between" sx={{ mt:1 }}>
        <Handle type="target" position={Position.Left} isConnectable={true} />
        <Handle type="source" position={Position.Right} isConnectable={true} />
        <Button size="small" onClick={() => data.onConfigure(data.id)}>Configure</Button>
      </Stack>
    </Paper>
  );
}

function VisualizationNode({ data }) {
  return (
    <Paper elevation={3} sx={{
      p:1,
      border: '1px solid #ccc',
      minWidth: 150,
      backgroundColor: '#f5e1f5',
      borderRadius: 0
    }}>
      <Typography variant="body2" align="center" sx={{ fontWeight: 'bold' }}>
        {data.config?.name || 'Visualization Node'}
      </Typography>
      <Typography variant="caption" display="block" align="center">
        (Visualization)
      </Typography>
      <Stack direction="row" justifyContent="flex-start" sx={{ mt:1 }}>
        <Handle type="target" position={Position.Left} isConnectable={true} />
        <Button size="small" onClick={() => data.onConfigure(data.id)}>Configure</Button>
      </Stack>
    </Paper>
  );
}

const nodeTypes = {
  retrievalNode: RetrievalNode,
  transformationNode: TransformationNode,
  visualizationNode: VisualizationNode
};

function FlowCanvas({
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  onConnect,
  onConfigureNode
}) {
  const enhancedNodes = nodes.map(n => ({
    ...n,
    data: {
      ...n.data,
      id: n.id,
      onConfigure: onConfigureNode
    }
  }));

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <ReactFlow
        nodes={enhancedNodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
      >
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
    </Box>
  );
}

export default FlowCanvas;
