import React from 'react';
import { Typography } from '@mui/material';

// Create a wrapper for Typography that formats text
export const FormattedTypography = ({ children, ...props }) => {
  if (typeof children === 'string') {
    return <Typography {...props}>{children.replace(/_/g, ' ')}</Typography>;
  }
  return <Typography {...props}>{children}</Typography>;
};

// Create a provider that overrides MUI's Typography
export const TextFormatterProvider = ({ children }) => {
  return React.Children.map(children, child => {
    if (!React.isValidElement(child)) return child;
    
    if (child.type === Typography) {
      return <FormattedTypography {...child.props} />;
    }
    
    return child;
  });
};

export const formatText = (text) => text?.replace(/_/g, ' ') ?? '';
