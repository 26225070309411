import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Button,
  Stack,
  Checkbox,
  ListItemText,
  IconButton,
  Collapse,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { useDashboard } from '../../contexts/DashboardContext';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function DynamicFilters({
  tableSchema,
  onSubmit,
  loading,
  error
}) {
  const { companyName } = useAuth();
  const {
    filterState,
    setFilterState,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useDashboard();

  const [metadataExpanded, setMetadataExpanded] = useState(false);
  const [staticExpanded, setStaticExpanded] = useState(false);
  const [metadataValues, setMetadataValues] = useState({});

  // Separate metadata and static columns
  const { metadataColumns, staticColumns } = useMemo(() => {
    if (!tableSchema) return { metadataColumns: [], staticColumns: [] };
    return {
      metadataColumns: tableSchema.metadataColumns || [],
      staticColumns: tableSchema.staticColumns || []
    };
  }, [tableSchema]);

  // Reset expansion state when table changes
  useEffect(() => {
    setMetadataExpanded(false);
    setStaticExpanded(false);
  }, [tableSchema]);

  // Fetch metadata values from Firestore
  useEffect(() => {
    const fetchMetadataValues = async () => {
      if (!companyName || !metadataColumns.length) return;

      const newMetadataValues = {};
      
      for (const column of metadataColumns) {
        try {
          const valuesRef = collection(db, `users/${companyName}/tag_categories/${column.name}/values`);
          const snapshot = await getDocs(valuesRef);
          newMetadataValues[column.name] = snapshot.docs.map(doc => doc.id);
        } catch (error) {
          console.error(`Error fetching values for ${column.name}:`, error);
          newMetadataValues[column.name] = [];
        }
      }

      setMetadataValues(newMetadataValues);
    };

    fetchMetadataValues();
  }, [companyName, metadataColumns]);

  // Initialize filter values
  useEffect(() => {
    if (!tableSchema) return;

    const newFilterState = { ...filterState };
    let hasNewFilters = false;

    [...metadataColumns, ...staticColumns].forEach(column => {
      if (!filterState.hasOwnProperty(column.name)) {
        newFilterState[column.name] = column.type === 'Text' ? [] : '';
        hasNewFilters = true;
      }
    });

    if (hasNewFilters) {
      setFilterState(newFilterState);
    }
  }, [tableSchema, filterState, setFilterState, metadataColumns, staticColumns]);

  const handleFilterChange = (columnName, value, isMetadata = false) => {
    setFilterState(prev => ({
      ...prev,
      [columnName]: isMetadata ? (Array.isArray(value) ? value : [value]) : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Only include non-empty filters
    const activeFilters = Object.entries(filterState).reduce((acc, [key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        acc[key] = value;
      } else if (value && typeof value === 'string' && value.trim() !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});

    onSubmit({
      ...activeFilters,
      startDate,
      endDate
    });
  };

  const renderMetadataFilter = (column) => {
    const value = filterState[column.name] || [];
    const availableValues = metadataValues[column.name] || [];

    return (
      <FormControl fullWidth key={column.name}>
        <InputLabel>{column.name.replace(/_/g, ' ')}</InputLabel>
        <Select
          multiple
          value={value}
          onChange={(e) => handleFilterChange(column.name, e.target.value, true)}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {availableValues.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={value.indexOf(item) > -1} />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderStaticFilter = (column) => {
    const value = filterState[column.name] || '';

    switch (column.type) {
      case 'Number':
        return (
          <TextField
            fullWidth
            key={column.name}
            label={column.name.replace(/_/g, ' ')}
            type="number"
            value={value}
            onChange={(e) => handleFilterChange(column.name, e.target.value)}
          />
        );

      case 'Text':
      default:
        return (
          <TextField
            fullWidth
            key={column.name}
            label={column.name.replace(/_/g, ' ')}
            value={value}
            onChange={(e) => handleFilterChange(column.name, e.target.value)}
          />
        );
    }
  };

  if (!tableSchema) {
    return null;
  }

  return (
    <Box sx={{ p: 2 }}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          {/* Date Range Filters */}
          <Typography variant="subtitle2">Date Range</Typography>
          <TextField
            fullWidth
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />

          {/* Metadata Filters */}
          {metadataColumns.length > 0 && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="subtitle2">Metadata Filters</Typography>
                <IconButton size="small" onClick={() => setMetadataExpanded(!metadataExpanded)}>
                  {metadataExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
              <Collapse in={metadataExpanded}>
                <Stack spacing={2}>
                  {metadataColumns.map(renderMetadataFilter)}
                </Stack>
              </Collapse>
            </>
          )}

          {/* Static Filters */}
          {staticColumns.length > 0 && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="subtitle2">Static Filters</Typography>
                <IconButton size="small" onClick={() => setStaticExpanded(!staticExpanded)}>
                  {staticExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
              <Collapse in={staticExpanded}>
                <Stack spacing={2}>
                  {staticColumns.map(renderStaticFilter)}
                </Stack>
              </Collapse>
            </>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            fullWidth
            sx={{ mt: 2 }}
          >
            {loading ? 'Loading...' : 'Apply Filters'}
          </Button>

          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
        </Stack>
      </form>
    </Box>
  );
}

export default DynamicFilters;