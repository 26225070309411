import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Tabs, Tab } from '@mui/material';
import ManageMetadata from '../components/manage/ManageMetadata';
import MappingsManager from '../components/manage/MappingsManager';
import UploadFiles from '../components/manage/UploadFiles';
import TableManager from '../components/manage/TableManager'; // We'll create this
import { useAuth } from '../contexts/AuthContext';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../services/firebase';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function ManageData() {
  const [value, setValue] = useState(0);
  const { currentUser, companyName } = useAuth();
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTables = async () => {
      try {
        const tablesRef = collection(db, `users/${companyName}/tables`);
        const tablesSnapshot = await getDocs(tablesRef);
        const tablesList = tablesSnapshot.docs.map(doc => ({
          ...doc.data(),
          name: doc.id
        }));
        setTables(tablesList);
      } catch (err) {
        console.error('Error fetching tables:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchTables();
  }, [companyName]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container component="main" maxWidth="lg" sx={{ mt: 8 }}>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Manage Data
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="manage data tabs">
          <Tab label="Metadata" />
          <Tab label="Mappings" />
          <Tab label="Upload Files" />
          <Tab label="Tables" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ManageMetadata companyName={companyName} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MappingsManager 
          companyId={companyName}
          tables={tables}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UploadFiles 
          companyId={companyName}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TableManager />
      </TabPanel>
    </Container>
  );
}

export default ManageData;