import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import SchemaBuilder from './SchemaBuilder';
import ExternalDataFields from './ExternalDataFields';
import MappingDefinition from './MappingDefinition';
import TagSelector from './TagSelector';

const steps = ['Basic Info', 'Schema Definition', 'External Fields', 'Column Mappings', 'Default Tags'];

const MappingEditor = ({ 
  open, 
  onClose, 
  table, 
  initialMapping = null, 
  companyId,
  availableMappings = []
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  const [mappingId, setMappingId] = useState('');
  const [schema, setSchema] = useState([{ name: '', children: [] }]);
  const [externalFields, setExternalFields] = useState([]);
  const [columnMapping, setColumnMapping] = useState({});
  const [mappingModes, setMappingModes] = useState({});
  const [selectedImport, setSelectedImport] = useState('');
  const [defaultTags, setDefaultTags] = useState([]);

  useEffect(() => {
    if (initialMapping) {
      setMappingId(initialMapping.id);
      setSchema(initialMapping.schema || [{ name: '', children: [] }]);
      setExternalFields(initialMapping.externalFields || []);
      setColumnMapping(initialMapping.mapping || {});
      setSelectedImport('');
      
      // Initialize mapping modes based on existing mappings
      const initialModes = {};
      Object.entries(initialMapping.mapping || {}).forEach(([key, value]) => {
        if (value && value.includes('`')) {
          initialModes[key] = 'formula';
        } else {
          initialModes[key] = 'direct';
        }
      });
      setMappingModes(initialModes);

      // Initialize default tags from the mapping
      if (initialMapping.default_tags) {
        const tagArray = Object.entries(initialMapping.default_tags).map(([category, value]) => ({
          category,
          value
        }));
        setDefaultTags(tagArray);
      } else {
        setDefaultTags([]);
      }
    } else {
      // Reset to default values when creating new mapping
      setMappingId('');
      setSchema([{ name: '', children: [] }]);
      setExternalFields([]);
      setColumnMapping({});
      setMappingModes({});
      setSelectedImport('');
      setDefaultTags([]);
    }
  }, [initialMapping, open]);

  const handleImportChange = (event) => {
    const selectedId = event.target.value;
    setSelectedImport(selectedId);

    if (selectedId) {
      const mappingToImport = availableMappings.find(m => m.id === selectedId);
      if (mappingToImport) {
        setSchema(mappingToImport.schema || [{ name: '', children: [] }]);
        setExternalFields(mappingToImport.externalFields || []);
        setColumnMapping(mappingToImport.mapping || {});

        // Initialize mapping modes based on imported mapping
        const importedModes = {};
        Object.entries(mappingToImport.mapping || {}).forEach(([key, value]) => {
          if (value && value.includes('`')) {
            importedModes[key] = 'formula';
          } else {
            importedModes[key] = 'direct';
          }
        });
        setMappingModes(importedModes);

        // Import default tags if they exist
        if (mappingToImport.default_tags) {
          const tagArray = Object.entries(mappingToImport.default_tags).map(([category, value]) => ({
            category,
            value
          }));
          setDefaultTags(tagArray);
        }
      }
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const getDynamicColumnPaths = (schema) => {
    const paths = [];
    
    Object.entries(schema).forEach(([key, value]) => {
      if (value.type) {
        // Single level nesting
        paths.push(`month.${key}`);
      } else {
        // Two level nesting
        Object.keys(value).forEach(subKey => {
          paths.push(`month.${key}.${subKey}`);
        });
      }
    });
    
    return paths;
  };

  const validateCurrentStep = () => {
    switch (activeStep) {
      case 0:
        return mappingId.trim() !== '';
      case 1:
        return schema.some(node => 
          node.name.trim() !== '' || 
          (node.children && node.children.some(child => child.name.trim() !== ''))
        );
      case 2:
        return true;
      case 3:
        if (!columnMapping[table.rowIdentifier.name]) return false;
        
        // Check static columns
        const staticColumnsMapped = table.staticColumns.every(col => 
          columnMapping[col.name] !== undefined
        );
        
        // Check month column
        const monthMapped = columnMapping['month'] !== undefined;
        
        // Check dynamic columns using flattened paths
        const requiredDynamicColumns = getDynamicColumnPaths(table.dynamicColumns.schema);
        const dynamicColumnsMapped = requiredDynamicColumns.every(path => 
          columnMapping[path] !== undefined
        );
        
        return staticColumnsMapped && monthMapped && dynamicColumnsMapped;
      case 4:
        return true; // Default tags are optional
      default:
        return false;
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      setError('');

      const cleanSchema = schema.filter(node => 
        node.name.trim() !== '' || 
        (node.children && node.children.some(child => child.name.trim() !== ''))
      );

      // Convert default tags array to object format
      const defaultTagsObject = defaultTags.reduce((acc, tag) => {
        acc[tag.category] = tag.value;
        return acc;
      }, {});

      const mappingData = {
        id: mappingId,
        table: table.name,
        schema: cleanSchema,
        externalFields,
        mapping: columnMapping,
        default_tags: defaultTagsObject,
        createdAt: new Date().toISOString()
      };

      const mappingRef = doc(db, `users/${companyId}/mappings/${mappingId}`);
      await setDoc(mappingRef, mappingData);

      onClose(mappingData);
    } catch (err) {
      console.error('Error saving mapping:', err);
      setError('Failed to save mapping');
    } finally {
      setLoading(false);
    }
  };

  const handleMappingChange = (newMapping, newModes) => {
    setColumnMapping(newMapping);
    setMappingModes(newModes);
  };

  const handleDefaultTagsChange = (newTags) => {
    setDefaultTags(newTags);
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Mapping ID"
              value={mappingId}
              onChange={(e) => setMappingId(e.target.value)}
              disabled={!!initialMapping}
              error={mappingId.trim() === ''}
              helperText={mappingId.trim() === '' ? 'Mapping ID is required' : ''}
              sx={{ mb: 2 }}
            />
            {!initialMapping && (
              <FormControl fullWidth>
                <InputLabel>Import From Existing Mapping</InputLabel>
                <Select
                  value={selectedImport}
                  onChange={handleImportChange}
                  label="Import From Existing Mapping"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {availableMappings.map((mapping) => (
                    <MenuItem key={mapping.id} value={mapping.id}>
                      {mapping.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        );
      case 1:
        return (
          <SchemaBuilder
            initialSchema={schema}
            onSchemaChange={setSchema}
          />
        );
      case 2:
        return (
          <ExternalDataFields
            fields={externalFields}
            onChange={setExternalFields}
          />
        );
      case 3:
        return (
          <MappingDefinition
            table={table}
            schema={schema}
            externalFields={externalFields}
            initialMapping={columnMapping}
            initialModes={mappingModes}
            onChange={handleMappingChange}
          />
        );
      case 4:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Default Tags
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Define default tags that will be automatically applied when this mapping is selected during file upload.
            </Typography>
            <TagSelector
              companyId={companyId}
              tableMetadataColumns={table.metadataColumns.map(col => col.name)}
              selectedTags={defaultTags}
              onTagsChange={handleDefaultTagsChange}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={() => !loading && onClose()} 
      maxWidth="md" 
      fullWidth
    >
      <DialogTitle>
        {initialMapping ? 'Edit Mapping' : 'Create New Mapping'}
      </DialogTitle>
      
      <DialogContent>
        <Stepper activeStep={activeStep} sx={{ mt: 2, mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        {renderStepContent()}
      </DialogContent>

      <DialogActions>
        <Button 
          onClick={onClose} 
          disabled={loading}
        >
          Cancel
        </Button>
        {activeStep > 0 && (
          <Button 
            onClick={handleBack}
            disabled={loading}
          >
            Back
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <Button 
            onClick={handleSave}
            variant="contained"
            disabled={loading || !validateCurrentStep()}
          >
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        ) : (
          <Button
            onClick={handleNext}
            variant="contained"
            disabled={!validateCurrentStep()}
          >
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MappingEditor;