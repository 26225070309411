// AssistantSidebar.js
import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../contexts/AuthContext';
import { mainAuth, db } from '../../services/firebase';
import { getIdToken } from 'firebase/auth';
import { collection, doc, getDocs, getDoc } from 'firebase/firestore';
import MessageDisplay from './MessageDisplay';
import MessageInput from './MessageInput';
import ChatHistory from './ChatHistory';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const Header = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Content = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  padding: theme.spacing(2),
}));

const InputContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const AssistantIdText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
}));

function AssistantSidebar({ open, onClose }) {
  const { currentUser, companyName } = useAuth();
  const [assistantId, setAssistantId] = useState('');
  const [messages, setMessages] = useState([]);
  const [assistantList, setAssistantList] = useState([]);
  const [showChatHistory, setShowChatHistory] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      fetchAssistantList();
    }
  }, [open]);

  useEffect(() => {
    if (assistantId) {
      fetchMessages();
    } else {
      setMessages([]);
    }
  }, [assistantId]);

  const fetchAssistantList = async () => {
    try {
      const assistantsRef = collection(db, 'users', companyName, 'assistants');
      const assistantsSnapshot = await getDocs(assistantsRef);
      const assistants = assistantsSnapshot.docs.map(doc => doc.id);
      setAssistantList(assistants);
    } catch (error) {
      console.error('Error fetching assistant list:', error);
    }
  };

  const fetchMessages = async () => {
    try {
      setLoading(true);
      const assistantDocRef = doc(db, 'users', companyName, 'assistants', assistantId);
      const assistantDoc = await getDoc(assistantDocRef);
      if (assistantDoc.exists()) {
        const assistantData = assistantDoc.data();
        const fetchedDisplayMessages = assistantData.display_messages || [];
        // Reconstruct messages array with roles and charts
        const reconstructedMessages = fetchedDisplayMessages.map((messageData, index) => {
          if (messageData.type === 'user') {
            return {
              role: 'user',
              content: messageData.message,
            };
          } else if (messageData.type === 'assistant') {
            let charts = [];
            if (messageData.charts) {
              try {
                charts = JSON.parse(messageData.charts);
              } catch (e) {
                console.error('Error parsing charts from Firestore:', e);
                charts = [];
              }
            }
            return {
              role: 'assistant',
              content: messageData.message,
              charts: charts,
            };
          } else {
            // Fallback for old format
            if (typeof messageData === 'string') {
              return {
                role: index % 2 === 0 ? 'user' : 'assistant',
                content: messageData,
              };
            } else {
              return {
                role: index % 2 === 0 ? 'user' : 'assistant',
                content: messageData.message || '',
                charts: messageData.charts || [],
              };
            }
          }
        });
        setMessages(reconstructedMessages);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendMessage = async (userMessage) => {
    // Add user's message and assistant's loading message immediately
    setMessages(prevMessages => [
      ...prevMessages,
      { role: 'user', content: userMessage },
      { role: 'assistant', content: '', loading: true }
    ]);

    try {
      const idToken = await getIdToken(mainAuth.currentUser);
      const headers = {
        Authorization: `Bearer ${idToken}`
      };

      const payload = {
        assistant_id: assistantId,
        user_message: userMessage,
      };

      const response = await axios.post('https://europe-west2-biminsurance-d5783.cloudfunctions.net/ask_assistant', payload, { headers });

      let assistantReply = response.data;
      if (typeof assistantReply === 'string') {
        try {
          assistantReply = JSON.parse(assistantReply);
        } catch (error) {
          console.error("Error parsing assistantReply:", error);
          assistantReply = {};
        }
      }

      console.log("ASSISTANT REPLY:", assistantReply);

      const assistantResponseText = assistantReply.response || 'Error: No response from assistant.';
      let assistantCharts = assistantReply.charts || [];
      console.log("ASSISTANT RESPONSE TEXT:", assistantResponseText);
      console.log("ASSISTANT CHARTS:", assistantCharts);

      if (typeof assistantCharts === 'string') {
        try {
          assistantCharts = JSON.parse(assistantCharts);
        } catch (e) {
          console.error('Error parsing assistantCharts:', e);
          assistantCharts = [];
        }
      }

      // Replace the loading assistant message with the actual reply
      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        const lastIndex = updatedMessages.length - 1;
        if (updatedMessages[lastIndex].role === 'assistant' && updatedMessages[lastIndex].loading) {
          updatedMessages[lastIndex] = {
            role: 'assistant',
            content: assistantResponseText,
            charts: assistantCharts,
            loading: false
          };
        } else {
          updatedMessages.push({
            role: 'assistant',
            content: assistantResponseText,
            charts: assistantCharts,
            loading: false
          });
        }
        return updatedMessages;
      });

      // Update assistant list if new assistant was created
      if (!assistantList.includes(assistantId)) {
        setAssistantList([...assistantList, assistantId]);
      }

    } catch (error) {
      console.error('Error sending message:', error);
      // Handle error - replace the loading message with an error message
      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        const lastIndex = updatedMessages.length - 1;
        if (updatedMessages[lastIndex].role === 'assistant' && updatedMessages[lastIndex].loading) {
          updatedMessages[lastIndex] = { role: 'assistant', content: 'Error: Could not get a response.', loading: false };
        }
        return updatedMessages;
      });
    }
  };

  const handleNewChat = () => {
    const newAssistantId = uuidv4().substring(0, 8);
    setAssistantId(newAssistantId);
    setMessages([]);
    setShowChatHistory(false);
  };

  const handleSelectAssistant = (selectedAssistantId) => {
    setAssistantId(selectedAssistantId);
    setShowChatHistory(false);
  };

  const toggleChatHistory = () => {
    setShowChatHistory(!showChatHistory);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      variant="persistent"
      sx={{
        '& .MuiDrawer-paper': {
          width: { xs: '100%', sm: '30%' },
          boxSizing: 'border-box',
        },
      }}
    >
      <Header>
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', left: theme => theme.spacing(2) }}
        >
          <ChevronRightIcon />
        </IconButton>
        <AssistantIdText variant="h6">
          {assistantId || 'No Assistant Selected'}
        </AssistantIdText>
        <IconButton
          onClick={toggleChatHistory}
          sx={{ position: 'absolute', right: theme => theme.spacing(8) }}
        >
          <HistoryIcon />
        </IconButton>
        <IconButton
          onClick={handleNewChat}
          sx={{ position: 'absolute', right: theme => theme.spacing(2) }}
        >
          <AddIcon />
        </IconButton>
      </Header>
      <Content>
        <MessageDisplay messages={messages} loading={loading} />
      </Content>
      <InputContainer>
        <MessageInput
          onSendMessage={handleSendMessage}
          isDisabled={assistantId === ''}
        />
      </InputContainer>
      {showChatHistory && (
        <ChatHistory
          assistantList={assistantList}
          onSelectAssistant={handleSelectAssistant}
          onClose={() => setShowChatHistory(false)}
        />
      )}
    </Drawer>
  );
}

export default AssistantSidebar;
