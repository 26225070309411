import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  CircularProgress, 
  List,
  Divider,
  Button,
  Stack
} from '@mui/material';
import { 
  collection, 
  getDocs, 
  query, 
  orderBy, 
  where, 
  limit, 
  startAfter 
} from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useAuth } from '../../contexts/AuthContext';
import BordereauFile from './BordereauFile';
import FilterPanel from './FilterPanel';
import { format, parse, isValid } from 'date-fns';

const PAGE_SIZE = 50;

function UploadedFilesViewer({ table }) {
  const [filesByMonth, setFilesByMonth] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [totalFiles, setTotalFiles] = useState(0);
  const [activeFilters, setActiveFilters] = useState({
    startDate: new Date('2000-01-01'),
    endDate: new Date(),
    tags: {}
  });
  
  const { companyName } = useAuth();

  const fetchFiles = async (isNewQuery = true) => {
    try {
      const filesRef = collection(db, `users/${companyName}/tables/${table.id}/raw_spreadsheets`);
      let q = query(filesRef, orderBy('date', 'desc'));

      // Apply date filters
      if (activeFilters.startDate && activeFilters.endDate) {
        q = query(q, 
          where('date', '>=', format(activeFilters.startDate, 'yyyy-MM-dd')),
          where('date', '<=', format(activeFilters.endDate, 'yyyy-MM-dd'))
        );
      }

      // Get all documents first
      const querySnapshot = await getDocs(q);
      
      // Filter in memory for metadata fields
      let filteredDocs = querySnapshot.docs;
      
      // Apply metadata filters
      if (Object.keys(activeFilters.tags).length > 0) {
        filteredDocs = filteredDocs.filter(doc => {
          const metadata = doc.data().metadata || {};
          return Object.entries(activeFilters.tags).every(([category, value]) => 
            metadata[category] === value
          );
        });
      }

      // Apply pagination
      const startIndex = isNewQuery ? 0 : Object.values(filesByMonth).flat().length;
      const paginatedDocs = filteredDocs.slice(startIndex, startIndex + PAGE_SIZE);
      
      // Update pagination state
      setLastVisible(paginatedDocs[paginatedDocs.length - 1]);
      setHasMore(paginatedDocs.length === PAGE_SIZE);

      // Process files
      const newFiles = paginatedDocs.map(doc => ({
        id: doc.id,
        path: `users/${companyName}/tables/${table.id}/raw_spreadsheets`,
        ...doc.data()
      }));

      // Group files by month
      const groupedFiles = newFiles.reduce((acc, file) => {
        let monthKey;
        if (file.date && isValid(parse(file.date, 'yyyy-MM-dd', new Date()))) {
          const date = parse(file.date, 'yyyy-MM-dd', new Date());
          monthKey = format(date, 'MMMM yyyy');
        } else {
          monthKey = 'Processing';
        }
        
        if (!acc[monthKey]) {
          acc[monthKey] = [];
        }
        acc[monthKey].push(file);
        return acc;
      }, isNewQuery ? {} : {...filesByMonth});

      // Sort groups
      const sortedGroups = Object.entries(groupedFiles).sort(([keyA], [keyB]) => {
        if (keyA === 'Processing') return -1;
        if (keyB === 'Processing') return 1;
        return new Date(parse(keyB.split(' ')[1], 'yyyy', new Date())) - 
               new Date(parse(keyA.split(' ')[1], 'yyyy', new Date())) ||
               new Date(parse(keyB.split(' ')[0], 'MMMM', new Date())) - 
               new Date(parse(keyA.split(' ')[0], 'MMMM', new Date()));
      });

      setFilesByMonth(Object.fromEntries(sortedGroups));
      
      // Update total count on new query
      if (isNewQuery) {
        setTotalFiles(filteredDocs.length);
      }

      setError(null);
    } catch (err) {
      console.error('Error fetching files:', err);
      setError('Failed to load files. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (companyName && table) {
      fetchFiles(true);
    }
  }, [companyName, table, activeFilters]);

  const handleLoadMore = () => {
    fetchFiles(false);
  };

  const handleDeleteFile = async (fileId) => {
    setFilesByMonth(prevGroups => {
      const newGroups = { ...prevGroups };
      
      Object.keys(newGroups).forEach(monthKey => {
        newGroups[monthKey] = newGroups[monthKey].filter(file => file.id !== fileId);
        if (newGroups[monthKey].length === 0) {
          delete newGroups[monthKey];
        }
      });
      
      return newGroups;
    });
    setTotalFiles(prev => prev - 1);
  };

  const handleApplyFilters = (filters) => {
    setFilesByMonth({});
    setLastVisible(null);
    setHasMore(true);
    setActiveFilters(filters);
  };

  if (loading && Object.keys(filesByMonth).length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <FilterPanel 
        onApplyFilters={handleApplyFilters}
        totalFiles={totalFiles}
      />
      
      {Object.keys(filesByMonth).length === 0 ? (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          height: '200px'
        }}>
          <Typography variant="body1" color="textSecondary">
            No files found matching the current filters.
          </Typography>
        </Box>
      ) : (
        <>
          {Object.entries(filesByMonth).map(([month, files], index) => (
            <Box key={month}>
              {index > 0 && <Divider sx={{ my: 2 }} />}
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 2,
                  color: 'text.secondary',
                  fontSize: '1.1rem',
                  fontWeight: 500
                }}
              >
                {month}
              </Typography>
              <List>
                {files.map((file) => (
                  <BordereauFile
                    key={file.id}
                    file={file}
                    onDelete={handleDeleteFile}
                  />
                ))}
              </List>
            </Box>
          ))}
          
          {hasMore && (
            <Stack 
              direction="row" 
              justifyContent="center" 
              sx={{ mt: 3 }}
            >
              <Button 
                variant="outlined" 
                onClick={handleLoadMore}
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Load More'}
              </Button>
            </Stack>
          )}
        </>
      )}
    </Box>
  );
}

export default UploadedFilesViewer;