import React from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography,
  Box 
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderRight: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  '&:last-child': {
    borderRight: 'none',
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  fontWeight: 500,
}));

function TableSchemaViewer({ schema }) {
  const isNestedValue = (value) => {
    return typeof value === 'object' && value.type === undefined;
  };

  // Function to render dynamic column structure
  const renderDynamicColumns = () => {
    if (!schema.dynamicColumns) return null;

    const dynamicSchema = schema.dynamicColumns.schema;
    let totalColumns = 0;

    // Calculate total columns needed
    Object.entries(dynamicSchema).forEach(([key, value]) => {
      if (isNestedValue(value)) {
        totalColumns += Object.keys(value).length;
      } else {
        totalColumns += 1;
      }
    });

    // Create header rows
    const headers = [
      [{ text: 'Example Month', span: totalColumns }], // Month row
      [], // Second row for first level keys
      []  // Third row for second level keys or type info
    ];

    // Process schema entries
    Object.entries(dynamicSchema).forEach(([key, value]) => {
      if (isNestedValue(value)) {
        // Nested structure
        const nestedSpan = Object.keys(value).length;
        headers[1].push({ text: key, span: nestedSpan });

        Object.entries(value).forEach(([nestedKey, nestedValue]) => {
          headers[2].push({ 
            text: `${nestedKey} (${nestedValue.type}, ${nestedValue.updateBehavior})`, 
            span: 1 
          });
        });
      } else {
        // Single layer
        headers[1].push({ 
          text: `${key} (${value.type}, ${value.updateBehavior})`, 
          span: 1,
          rowSpan: 2 
        });
      }
    });

    return (
      <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
        <Table size="small">
          <TableHead>
            {headers.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <StyledTableCell 
                    key={cellIndex}
                    align="center"
                    colSpan={cell.span}
                    rowSpan={cell.rowSpan}
                    sx={{ backgroundColor: '#f5f5f5' }}
                  >
                    {cell.text}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </TableContainer>
    );
  };

  // Function to render static columns table
  const renderStaticColumns = (columns, title) => {
    if (!columns || columns.length === 0) return null;

    return (
      <>
        <SectionTitle variant="h6">{title}</SectionTitle>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ backgroundColor: '#f5f5f5' }}>Column Name</StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: '#f5f5f5' }}>Type</StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: '#f5f5f5' }}>Description</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {columns.map((column, index) => (
                <TableRow key={index}>
                  <StyledTableCell>{column.name}</StyledTableCell>
                  <StyledTableCell>{column.type}</StyledTableCell>
                  <StyledTableCell>{column.description || '-'}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <Box>
      {/* Row Identifier */}
      {renderStaticColumns([schema.rowIdentifier], 'Row Identifier')}

      {/* Metadata Columns */}
      {renderStaticColumns(schema.metadataColumns, 'Metadata Columns')}

      {/* Static Columns */}
      {renderStaticColumns(schema.staticColumns, 'Static Columns')}

      {/* Dynamic Columns */}
      {schema.dynamicColumns && (
        <>
          <SectionTitle variant="h6">Dynamic Columns</SectionTitle>
          {renderDynamicColumns()}
        </>
      )}
    </Box>
  );
}

export default TableSchemaViewer;