import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Tabs, 
  Tab, 
  Paper,
  CircularProgress,
  Typography,
  Alert
} from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { useDashboard } from '../../contexts/DashboardContext';
import DataTable from './DataTable';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`table-tabpanel-${index}`}
      aria-labelledby={`table-tab-${index}`}
      style={{ 
        height: '100%', 
        display: value === index ? 'flex' : 'none',
        overflow: 'hidden' // Add this
      }}
      {...other}
    >
      {value === index && (
        <Box sx={{ 
          flex: 1, 
          display: 'flex', 
          flexDirection: 'column',
          overflow: 'hidden' // Add this
        }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `table-tab-${index}`,
    'aria-controls': `table-tabpanel-${index}`,
  };
}

function TableTabs() {
  const { companyName } = useAuth();
  const { 
    tables,
    setTables,
    currentTableId,
    setCurrentTableId,
    tableData
  } = useDashboard();

  const [error, setError] = useState(null);
  const [fetchingTables, setFetchingTables] = useState(true);

  // Fetch table definitions from Firestore
  useEffect(() => {
    const fetchTables = async () => {
      if (!companyName) return;

      try {
        const tablesCollection = collection(db, `users/${companyName}/tables`);
        const tablesSnapshot = await getDocs(tablesCollection);
        const tablesList = tablesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setTables(tablesList);
        if (tablesList.length > 0 && !currentTableId) {
          setCurrentTableId(tablesList[0].id);
        }
      } catch (err) {
        console.error('Error fetching tables:', err);
        setError('Failed to load tables');
      } finally {
        setFetchingTables(false);
      }
    };

    fetchTables();
  }, [companyName, setTables, currentTableId, setCurrentTableId]);

  const handleTabChange = (event, newValue) => {
    setCurrentTableId(newValue);
  };

  if (fetchingTables) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!tables || tables.length === 0) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6">
          No tables found. Please create a table first.
        </Typography>
      </Box>
    );
  }

  const currentTable = tables.find(table => table.id === currentTableId);
  const currentData = tableData[currentTableId] || [];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Paper sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={currentTableId} 
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tables.map((table) => (
            <Tab 
              key={table.id}
              label={table.name}
              value={table.id}
              {...a11yProps(table.id)}
            />
          ))}
        </Tabs>
      </Paper>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      {tables.map((table) => (
        <TabPanel 
          key={table.id} 
          value={currentTableId} 
          index={table.id}
        >
          <DataTable
            data={currentData}
            tableSchema={currentTable}
          />
        </TabPanel>
      ))}
    </Box>
  );
}

export default TableTabs;