import React from 'react';
import { 
  Grid, 
  Paper,
  Typography,
} from '@mui/material';
import { useDashboard } from '../../contexts/DashboardContext';
import DynamicFilters from './DynamicFilters';

function DataSidebar({ onSubmit, loading, error }) {
  const { 
    tables,
    currentTableId,
  } = useDashboard();

  // Find the current table schema
  const currentTable = tables.find(table => table.id === currentTableId);

  return (
    <Grid container direction="column" spacing={3}>
      {/* Filters Section */}
      <Grid item>
        <Paper sx={{ p: 2 }}>
          {currentTable ? (
            <DynamicFilters
              tableSchema={currentTable}
              onSubmit={onSubmit}
              loading={loading}
              error={error}
            />
          ) : (
            <Typography variant="body2" color="text.secondary">
              No table selected
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default DataSidebar;