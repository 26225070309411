import React, { useState } from 'react';
import { mainAuth } from '../../services/firebase';
import { getIdToken } from 'firebase/auth';
import { 
  Typography, 
  ListItem, 
  ListItemText, 
  IconButton, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  Paper, 
  CircularProgress, 
  Collapse, 
  List 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { styled } from '@mui/material/styles';
import { FetchSubmitButton } from '../common/Buttons';
import { formatText } from '../utils/TextFormatterProvider';
import axios from 'axios';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0, 0),
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontWeight: 500,
    wordBreak: 'break-word',
  },
  '& .MuiListItemText-secondary': {
    color: theme.palette.text.secondary,
  },
}));

function BordereauFile({ file, onDelete }) {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    const idToken = await getIdToken(mainAuth.currentUser);
    const headers = {
      Authorization: `Bearer ${idToken}`
    };
    try {
      await axios.post('https://europe-west2-biminsurance-d5783.cloudfunctions.net/delete_spreadsheet', {
        document_path: `${file.path}/${file.id}`
      }, { headers });

      await onDelete(file.id);
    } catch (error) {
      console.error('Error deleting file:', error);
    } finally {
      setIsDeleting(false);
      setDeleteConfirmation(false);
    }
  };

  const getFileStatusIcon = () => {
    // Add null check for cleaned_sheets
    if (!file.cleaned_sheets) {
      return <CircularProgress size={20} />;
    }
  
    const statuses = file.cleaned_sheets.map(sheet => sheet.status);
    if (statuses.includes('Processing')) {
      return <CircularProgress size={20} />;
    } else if (statuses.every(status => status === 'Completed')) {
      return <CheckCircleIcon color="success" />;
    } else if (statuses.every(status => status === 'Failed')) {
      return <ErrorIcon color="error" />;
    } else {
      return <WarningIcon color="warning" />;
    }
  };
  const getSheetStatusIcon = (status) => {
    switch (status) {
      case 'Completed':
        return <CheckCircleIcon color="success" />;
      case 'Pending':
      case 'Processing':
        return <CircularProgress size={20} />;
      case 'Failed':
        return <ErrorIcon color="error" />;
      default:
        return null;
    }
  };

  return (
    <Paper elevation={3} style={{ margin: '10px 0', padding: '10px' }}>
      <StyledListItem>
        <IconButton onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <StyledListItemText 
          primary={<Typography>{formatText(file.name)}</Typography>} 
          secondary={<Typography variant="body2">{formatText(file.date)}</Typography>}
        />
        {getFileStatusIcon()}
        <IconButton onClick={() => setDeleteConfirmation(true)}>
          <DeleteIcon />
        </IconButton>
      </StyledListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {file.cleaned_sheets?.map((sheet, index) => (
            <ListItem key={index} style={{ paddingLeft: 32 }}>
              <ListItemText 
                primary={<Typography>{formatText(sheet.sheet_name)}</Typography>} 
              />
              {getSheetStatusIcon(sheet.status)}
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Dialog 
        open={deleteConfirmation} 
        onClose={() => !isDeleting && setDeleteConfirmation(false)}
        PaperProps={{
          style: {
            opacity: 1,
          },
        }}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {formatText(file.fileName)}? This will delete this document from our system, and remove its data from our database. It cannot be undone, though you can always re-upload.
          </DialogContentText>
          {isDeleting && (
            <CircularProgress 
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -10,
                marginLeft: -10,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <FetchSubmitButton onClick={() => setDeleteConfirmation(false)} disabled={isDeleting} color="primary">
            No
          </FetchSubmitButton>
          <FetchSubmitButton onClick={handleDelete} color="primary" disabled={isDeleting}>
            Yes
          </FetchSubmitButton>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default BordereauFile;