import React, { useState } from 'react';
import { 
  Grid, 
  Card, 
  CardContent, 
  CardActions, 
  Typography, 
  Button, 
  Dialog,
  IconButton,
  Box,
  Tooltip,
  Stack
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';
import TableSchemaViewer from './TableSchemaViewer';
import UploadedFilesViewer from './UploadedFilesViewer';

function TableList({ tables }) {
  const [selectedTable, setSelectedTable] = useState(null);
  const [dialogType, setDialogType] = useState(null);

  const handleViewSchema = (table) => {
    setSelectedTable(table);
    setDialogType('schema');
  };

  const handleViewFiles = (table) => {
    setSelectedTable(table);
    setDialogType('files');
  };

  const handleCloseDialog = () => {
    setSelectedTable(null);
    setDialogType(null);
  };

  if (!tables.length) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '200px'
      }}>
        <Typography variant="body1" color="textSecondary">
          No tables created yet. Click "Create New Table" to get started.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={3}>
        {tables.map((table) => (
          <Grid item xs={12} sm={6} md={4} key={table.id}>
            <Card 
              sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                '&:hover': {
                  boxShadow: (theme) => theme.shadows[4]
                }
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {table.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {table.description || 'No description provided'}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    Row Identifier: {table.rowIdentifier.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Metadata Columns: {table.metadataColumns?.length || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Static Columns: {table.staticColumns?.length || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Dynamic Columns: {table.dynamicColumns ? 'Yes' : 'No'}
                  </Typography>
                </Box>
              </CardContent>
              <CardActions>
                <Stack direction="row" spacing={1}>
                  <Tooltip title="View Schema">
                    <Button
                      size="small"
                      startIcon={<VisibilityIcon />}
                      onClick={() => handleViewSchema(table)}
                    >
                      Schema
                    </Button>
                  </Tooltip>
                  <Tooltip title="View Files">
                    <Button
                      size="small"
                      startIcon={<FileIcon />}
                      onClick={() => handleViewFiles(table)}
                    >
                      Files
                    </Button>
                  </Tooltip>
                </Stack>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={!!selectedTable}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 2,
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <Typography variant="h6">
            {selectedTable?.name} {dialogType === 'schema' ? 'Schema' : 'Files'}
          </Typography>
          <IconButton onClick={handleCloseDialog} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        {selectedTable && (
          <Box sx={{ p: 2 }}>
            {dialogType === 'schema' ? (
              <TableSchemaViewer schema={selectedTable} />
            ) : (
              <UploadedFilesViewer table={selectedTable} />
            )}
          </Box>
        )}
      </Dialog>
    </>
  );
}

export default TableList;