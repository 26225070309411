// PreviewTable.js

import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography
} from '@mui/material';

const DELIMITER = '§';

function analyzeColumns(data) {
  if (!Array.isArray(data) || data.length === 0) {
    return { columns: [], maxDepth: 0 };
  }

  // Gather info about each column across all rows
  const columnInfo = {};

  // Collect all columns from all rows
  const allColumns = new Set();
  data.forEach(row => {
    Object.keys(row).forEach(col => allColumns.add(col));
  });

  // For each column, determine the structure and keys
  allColumns.forEach(col => {
    let depth = 0; // 0 means no nesting, 1 means single-level, 2 means double-level
    const singleLevelKeys = new Set();
    const doubleLevelKeys = {}; // { firstLevelKey: Set of leafKeys }

    data.forEach(row => {
      const val = row[col];
      if (val && typeof val === 'object' && !Array.isArray(val)) {
        // Check the structure of this dictionary
        // Determine if single-level or double-level
        Object.keys(val).forEach(subKey => {
          const subVal = val[subKey];
          if (subVal && typeof subVal === 'object' && !Array.isArray(subVal)) {
            // This indicates double-level nesting
            depth = Math.max(depth, 2);
            // Collect all leaf keys
            if (!doubleLevelKeys[subKey]) {
              doubleLevelKeys[subKey] = new Set();
            }
            Object.keys(subVal).forEach(leafKey => doubleLevelKeys[subKey].add(leafKey));
          } else {
            // single-level key
            if (depth < 2) {
              depth = Math.max(depth, 1);
              singleLevelKeys.add(subKey);
            }
          }
        });
      } else {
        // val is primitive or null
        // no direct change to depth here, but keep in mind if some rows have dictionaries.
      }
    });

    columnInfo[col] = { depth, singleLevelKeys, doubleLevelKeys };
  });

  // Determine global max depth
  let maxDepth = 0;
  Object.values(columnInfo).forEach(info => {
    if (info.depth > maxDepth) {
      maxDepth = info.depth;
    }
  });

  // Now, for each column, if it's depth=1 or depth=2, we need to ensure we have a full set of keys
  // For depth=1, collect all keys from all rows again (some rows might have None)
  // Actually we did that above, but let's unify sets for double-level:
  Object.keys(columnInfo).forEach(col => {
    const info = columnInfo[col];
    if (info.depth === 1) {
      // We already have singleLevelKeys as a set from all rows combined
      // But we must consider if some rows had None. We already accounted for that by scanning all rows at once.
    } else if (info.depth === 2) {
      // doubleLevelKeys is a dictionary of sets
      // We have already combined from all rows above.
    }
  });

  return { columns: columnInfo, maxDepth };
}

function buildHeaderStructure(data) {
  if (!Array.isArray(data) || data.length === 0) {
    return { top: [], middle: [], bottom: [], maxDepth: 0 };
  }

  const { columns, maxDepth } = analyzeColumns(data);

  const top = [];
  const middle = [];
  const bottom = [];

  // Based on maxDepth, structure the headers
  Object.keys(columns).forEach(col => {
    const { depth, singleLevelKeys, doubleLevelKeys } = columns[col];

    if (maxDepth === 2) {
      // We have triple-level headers
      if (depth === 0) {
        // No nesting, rowSpan=3
        top.push({ id: col, label: col, colSpan: 1, rowSpan: 3, depth: 0 });
      } else if (depth === 1) {
        // Single-level: top-level header + bottom headers, skip middle
        // The colSpan equals number of singleLevelKeys
        const keys = Array.from(singleLevelKeys);
        top.push({
          id: col,
          label: col,
          colSpan: keys.length || 1, // if no keys found but depth=1, treat as 1
          rowSpan: 1,
          depth: 1
        });
        // For single-level under a maxDepth=2 scenario, place keys on the bottom row
        keys.forEach(k => {
          bottom.push({ id: `${col}${DELIMITER}${k}`, label: k });
        });
      } else if (depth === 2) {
        // Double-level: top-level header, middle-level keys, bottom-level leaf keys
        // top-level colSpan = sum of all leaf keys of all middle keys
        let totalLeafCount = 0;
        Object.keys(doubleLevelKeys).forEach(mKey => {
          totalLeafCount += doubleLevelKeys[mKey].size;
        });
        top.push({ id: col, label: col, colSpan: totalLeafCount, rowSpan: 1, depth: 2 });

        // Middle row
        Object.keys(doubleLevelKeys).forEach(mKey => {
          const leafKeys = Array.from(doubleLevelKeys[mKey]);
          middle.push({
            id: `${col}${DELIMITER}${mKey}`,
            label: mKey,
            colSpan: leafKeys.length
          });
          // Bottom row
          leafKeys.forEach(lk => {
            bottom.push({
              id: `${col}${DELIMITER}${mKey}${DELIMITER}${lk}`,
              label: lk
            });
          });
        });
      }
    } else if (maxDepth === 1) {
      // We have two-level headers
      if (depth === 0) {
        // No nesting, rowSpan=2
        top.push({ id: col, label: col, colSpan: 1, rowSpan: 2, depth: 0 });
      } else if (depth === 1) {
        // Single-level: top-level header + bottom headers
        const keys = Array.from(singleLevelKeys);
        top.push({
          id: col,
          label: col,
          colSpan: keys.length || 1,
          rowSpan: 1,
          depth: 1
        });
        keys.forEach(k => {
          bottom.push({ id: `${col}${DELIMITER}${k}`, label: k });
        });
      } else {
        // Shouldn't happen if maxDepth=1, but just in case
        // If somehow we got a depth=2 column but maxDepth=1 (contradiction), treat as above scenario
        // This scenario logically can't occur since maxDepth was determined globally.
      }
    } else {
      // maxDepth = 0, only one header row
      // All columns are non-nested
      top.push({ id: col, label: col, colSpan: 1, rowSpan: 1, depth: 0 });
    }
  });

  return { top, middle, bottom, maxDepth };
}

function getCellValue(row, columnId) {
  const parts = columnId.split(DELIMITER);

  let value = row;
  for (const p of parts) {
    if (value && typeof value === 'object' && p in value) {
      value = value[p];
    } else {
      return '-';
    }
  }

  if (value === null || value === undefined) return '-';
  return value;
}

function PreviewTable({ data }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const processedData = useMemo(() => {
    if (!Array.isArray(data)) return [];
    return data;
  }, [data]);

  const { top, middle, bottom, maxDepth } = useMemo(() => {
    return buildHeaderStructure(processedData);
  }, [processedData]);

  if (!Array.isArray(processedData) || processedData.length === 0) {
    return (
      <Typography variant="body1" align="center" sx={{ p: 2, color: 'text.secondary' }}>
        No data available
      </Typography>
    );
  }

  // Determine the final leaves for rendering the rows
  // If maxDepth=2, leaves are bottom
  // If maxDepth=1, leaves are bottom
  // If maxDepth=0, leaves are top (since only one row)
  let leafHeaders;
  if (maxDepth === 2) {
    leafHeaders = bottom;
  } else if (maxDepth === 1) {
    leafHeaders = bottom;
  } else {
    // maxDepth = 0
    leafHeaders = top;
  }

  // Identify static (no nesting) columns: these appear in top row with maximum rowspan
  const staticLeaves = top.filter(h => h.depth === 0 && h.rowSpan === (maxDepth === 2 ? 3 : (maxDepth === 1 ? 2 : 1)));

  // Dynamic leaves = all bottom-level headers except those already in static leaves
  // Actually, let's just combine all leaf headers and exclude staticLeaves
  const dynamicLeaves = leafHeaders.filter(h => !staticLeaves.some(sh => sh.id === h.id));

  const styles = {
    tableCell: {
      padding: '6px 8px',
      fontSize: '0.8rem',
    },
    headerCell: {
      textAlign: 'center',
      borderRight: '1px solid #e0e0e0',
      borderBottom: '2px solid #e0e0e0',
      fontWeight: 'bold',
      backgroundColor: '#f0f0f0'
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
      <Paper sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <TableContainer sx={{ flexGrow: 1, overflow: 'auto' }}>
          <Table stickyHeader size="small">
            <TableHead>
              {/* Top Header Row */}
              {top.length > 0 && (
                <TableRow>
                  {top.map((header) => (
                    <TableCell
                      key={header.id}
                      colSpan={header.colSpan || 1}
                      rowSpan={header.rowSpan || 1}
                      style={styles.headerCell}
                    >
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              )}

              {/* Middle Header Row (if maxDepth=2 and we have middle headers) */}
              {middle.length > 0 && (
                <TableRow>
                  {middle.map((header) => (
                    <TableCell
                      key={header.id}
                      colSpan={header.colSpan || 1}
                      style={styles.headerCell}
                    >
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              )}

              {/* Bottom Header Row (if depth=1 or depth=2) */}
              {bottom.length > 0 && (
                <TableRow>
                  {bottom.map((header) => (
                    <TableCell
                      key={header.id}
                      style={styles.headerCell}
                    >
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {processedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <TableRow hover key={rowIndex}>
                    {/* Static leaves (no nesting columns) */}
                    {staticLeaves.map(hdr => (
                      <TableCell
                        key={`${rowIndex}-${hdr.id}`}
                        style={styles.tableCell}
                      >
                        {getCellValue(row, hdr.id)}
                      </TableCell>
                    ))}

                    {/* Dynamic leaves (nested columns final leaves) */}
                    {dynamicLeaves
                      .filter(h => !staticLeaves.some(sh => sh.id === h.id))
                      .map(hdr => (
                        <TableCell
                          key={`${rowIndex}-${hdr.id}`}
                          style={styles.tableCell}
                        >
                          {getCellValue(row, hdr.id)}
                        </TableCell>
                      ))
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '8px' }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={processedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </div>
      </Paper>
    </div>
  );
}

export default PreviewTable;
