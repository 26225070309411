// DataView.js

import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { mainAuth } from '../services/firebase';
import { getIdToken } from 'firebase/auth';
import { useDashboard } from '../contexts/DashboardContext';
import { Container, Grid, Paper, Fab } from '@mui/material';
import RobotIcon from '@mui/icons-material/Android';
import TableTabs from '../components/data/TableTabs';
import DataSidebar from '../components/sidebar/DataSidebar';
import AssistantSidebar from '../components/assistant/AssistantSidebar';

function DataView() {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAssistant, setShowAssistant] = useState(false);
  
  const { currentUser, companyName } = useAuth();
  const {
    currentTableId,
    filterState,
    startDate,
    endDate,
    setTableData,
    tables
  } = useDashboard();

  const filterDataByDisplayColumns = (data, displayColumns) => {
    if (!displayColumns) return data;
    return data.map(row => {
      const filteredRow = {...row};
      Object.keys(displayColumns).forEach(col => {
        if (!displayColumns[col]) {
          delete filteredRow[col];
        }
      });
      return filteredRow;
    });
  };

  const handleSubmit = async (filters) => {
    if (!currentTableId || !companyName) return;

    setLoading(true);
    setError('');

    const idToken = await getIdToken(mainAuth.currentUser);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`
    };

    try {
      const response = await fetch('https://europe-west2-biminsurance-d5783.cloudfunctions.net/get_table_data', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          company_id: companyName,
          table_id: currentTableId,
          filters: {
            ...filters,
            startDate,
            endDate
          }
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      
      // Get current table's display settings
      const currentTable = tables.find(table => table.id === currentTableId);
      const displayColumns = currentTable?.display_columns;

      // Filter the data based on display settings
      const filteredData = filterDataByDisplayColumns(data.rows || [], displayColumns);
      
      setTableData(prev => ({
        ...prev,
        [currentTableId]: filteredData
      }));
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.message || 'Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const toggleAssistant = () => {
    setShowAssistant(!showAssistant);
  };

  return (
    <Container maxWidth={false} sx={{ height: '100vh', pt: 2, pb: 4 }}>
      <Grid container spacing={3} sx={{ height: 'calc(100% - 64px)' }}>
        {/* Sidebar */}
        <Grid item xs={12} md={3} lg={2}>
          <DataSidebar
            onSubmit={handleSubmit}
            loading={loading}
            error={error}
          />
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} md={9} lg={10}>
        <Paper 
          sx={{ 
            p: 2, 
            display: 'flex', 
            flexDirection: 'column', 
            height: '85vh',
            minHeight: '85vh',
            overflow: 'hidden'
          }}
        >
            <TableTabs />
          </Paper>
        </Grid>
      </Grid>

      {/* AI Assistant Button */}
      <Fab
        color="primary"
        aria-label="assistant"
        onClick={toggleAssistant}
        sx={{
          position: 'fixed',
          bottom: (theme) => theme.spacing(4),
          right: (theme) => theme.spacing(4),
        }}
      >
        <RobotIcon />
      </Fab>

      {/* AI Assistant Sidebar */}
      <AssistantSidebar 
        open={showAssistant} 
        onClose={toggleAssistant} 
      />
    </Container>
  );
}

export default DataView;