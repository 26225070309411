import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useAuth } from '../../contexts/AuthContext';
import AddIcon from '@mui/icons-material/Add';
import TableList from '../tables/TableList';
import CreateTableForm from '../tables/CreateTableForm';

function TableManager() {
  const [isCreating, setIsCreating] = useState(false);
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { companyName } = useAuth();

  useEffect(() => {
    const fetchTables = async () => {
      try {
        const tablesRef = collection(db, `users/${companyName}/tables`);
        const tablesSnapshot = await getDocs(tablesRef);
        const tablesList = tablesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setTables(tablesList);
        setError(null);
      } catch (err) {
        console.error('Error fetching tables:', err);
        setError('Failed to load tables. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (companyName) {
      fetchTables();
    }
  }, [companyName]);

  const handleCreateTable = () => {
    setIsCreating(true);
  };

  const handleCancelCreate = () => {
    setIsCreating(false);
  };

  const handleTableCreated = (newTable) => {
    setTables(prevTables => [...prevTables, newTable]);
    setIsCreating(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box>
      {isCreating ? (
        <>
          <Typography variant="h6" gutterBottom>
            Create New Table
          </Typography>
          <CreateTableForm 
            onCancel={handleCancelCreate}
            onTableCreated={handleTableCreated}
          />
        </>
      ) : (
        <>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            mb: 3 
          }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleCreateTable}
            >
              Create New Table
            </Button>
          </Box>
          <TableList 
            tables={tables}
          />
        </>
      )}
    </Box>
  );
}

export default TableManager;