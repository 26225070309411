import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Chip,
  Typography,
  Select,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';

const TagSelector = ({ 
  companyId,
  tableMetadataColumns, 
  selectedTags, 
  onTagsChange 
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryValues, setCategoryValues] = useState([]);
  const [valueDialogOpen, setValueDialogOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [availableCategories, setAvailableCategories] = useState([]);

  // Fetch all tag categories and filter based on table metadata columns
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesRef = collection(db, `users/${companyId}/tag_categories`);
        const categoriesSnapshot = await getDocs(categoriesRef);
        const allCategories = categoriesSnapshot.docs.map(doc => doc.data().name);
        
        // Filter categories to only those that exist in both collections
        const intersectedCategories = allCategories.filter(
          category => tableMetadataColumns.includes(category)
        );
        
        setAvailableCategories(intersectedCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [companyId, tableMetadataColumns]);

  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCategoryClose = () => {
    setAnchorEl(null);
  };

  const handleCategorySelect = async (category) => {
    setSelectedCategory(category);
    handleCategoryClose();
    
    try {
      const valuesRef = collection(db, `users/${companyId}/tag_categories/${category}/values`);
      const valuesSnapshot = await getDocs(valuesRef);
      const values = valuesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCategoryValues(values);
      setValueDialogOpen(true);
    } catch (error) {
      console.error('Error fetching category values:', error);
    }
  };

  const handleValueSelect = () => {
    if (selectedValue) {
      const newTags = selectedTags.filter(tag => tag.category !== selectedCategory);
      newTags.push({
        category: selectedCategory,
        value: selectedValue
      });
      onTagsChange(newTags);
    }
    handleValueDialogClose();
  };

  const handleValueDialogClose = () => {
    setValueDialogOpen(false);
    setSelectedCategory(null);
    setSelectedValue('');
  };

  const handleRemoveTag = (categoryToRemove) => {
    const newTags = selectedTags.filter(tag => tag.category !== categoryToRemove);
    onTagsChange(newTags);
  };

  // Filter out categories that already have values selected
  const availableCategoriesFiltered = availableCategories.filter(
    category => !selectedTags.some(tag => tag.category === category)
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="subtitle1" sx={{ mr: 2 }}>
          Tags
        </Typography>
        <Button
          startIcon={<AddIcon />}
          onClick={handleAddClick}
          disabled={availableCategoriesFiltered.length === 0}
          size="small"
          variant="outlined"
        >
          Add Tag
        </Button>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCategoryClose}
      >
        {availableCategoriesFiltered.map((category) => (
          <MenuItem
            key={category}
            onClick={() => handleCategorySelect(category)}
          >
            {category}
          </MenuItem>
        ))}
      </Menu>

      <Dialog
        open={valueDialogOpen}
        onClose={handleValueDialogClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Select {selectedCategory} Value</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel>Value</InputLabel>
            <Select
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.target.value)}
              label="Value"
            >
              {categoryValues.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  {value.name || value.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleValueDialogClose}>Cancel</Button>
          <Button 
            onClick={handleValueSelect} 
            disabled={!selectedValue}
            variant="contained"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {selectedTags.map((tag) => (
          <Chip
            key={tag.category}
            label={`${tag.category}: ${tag.value}`}
            onDelete={() => handleRemoveTag(tag.category)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TagSelector;