import React, { useState, useMemo } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TablePagination,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';

const DELIMITER = '§';

const formatMonthHeader = (column) => {
  if (column.match(/^\d{4}[-_]\d{2}$/)) {
    const [year, month] = column.split(/[-_]/);
    const date = new Date(parseInt(year), parseInt(month) - 1);
    return date.toLocaleString('default', { month: 'short', year: '2-digit' });
  }
  return column.replace(/_/g, ' ');
};

const formatDateCell = (value) => {
  if (value && typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}$/)) {
    const [year, month, day] = value.split('-');
    const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    return date.toLocaleString('default', { day: '2-digit', month: 'short', year: '2-digit' });
  }
  return value;
};

const formatNumber = (value) => {
  if (typeof value === 'number' && !isNaN(value)) {
    return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  return value;
};

function DataTable({ data, tableSchema }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const processedData = useMemo(() => {
    if (!Array.isArray(data) || data.length === 0) return [];
    return data.map(row => ({ ...row }));
  }, [data]);

  const { staticColumns, metadataColumns, rowIdentifier } = tableSchema;

  // Identify which columns should be displayed
  const allStaticCols = [rowIdentifier, ...metadataColumns, ...staticColumns].filter(col => {
    // If display_columns is defined, check if column should be displayed
    if (tableSchema.display_columns && tableSchema.display_columns[col.name] === false) {
      return false;
    }
    return true;
  });

  // Identify all dynamic (month) columns by scanning keys of all rows
  const monthColumns = useMemo(() => {
    const months = new Set();
    processedData.forEach(row => {
      Object.keys(row).forEach(key => {
        if (key.match(/^\d{4}[-_]\d{2}$/)) {
          months.add(key);
        }
      });
    });
    return Array.from(months).sort().reverse();
  }, [processedData]);

  // Analyze all month columns to determine nesting and keys
  const analyzeColumns = (rows, monthCols) => {
    const columnInfo = {};

    monthCols.forEach(col => {
      let depth = 0;
      const singleLevelKeys = new Set();
      const doubleLevelKeys = {}; // { firstKey: Set of leafKeys }

      rows.forEach(r => {
        const val = r[col];
        if (val && typeof val === 'object' && !Array.isArray(val)) {
          // Check keys in this dictionary
          Object.keys(val).forEach(subKey => {
            const subVal = val[subKey];
            if (subVal && typeof subVal === 'object' && !Array.isArray(subVal)) {
              // double-level
              depth = Math.max(depth, 2);
              if (!doubleLevelKeys[subKey]) {
                doubleLevelKeys[subKey] = new Set();
              }
              Object.keys(subVal).forEach(leafKey => doubleLevelKeys[subKey].add(leafKey));
            } else {
              // single-level key
              if (depth < 2) {
                depth = Math.max(depth, 1);
                singleLevelKeys.add(subKey);
              }
            }
          });
        } else {
          // val is primitive or null => could be depth=0 unless we found deeper in other rows
        }
      });

      columnInfo[col] = { depth, singleLevelKeys, doubleLevelKeys };
    });

    return columnInfo;
  };

  const columnInfo = useMemo(() => {
    if (!processedData.length) return {};
    return analyzeColumns(processedData, monthColumns);
  }, [processedData, monthColumns]);

  // Determine global max depth
  const maxDepth = useMemo(() => {
    let mDepth = 0;
    Object.values(columnInfo).forEach(info => {
      if (info.depth > mDepth) {
        mDepth = info.depth;
      }
    });
    return mDepth;
  }, [columnInfo]);

  // Build header structure
  const getHeaderStructure = useMemo(() => {
    if (!processedData.length) {
      return { staticHeaders: [], dynamicHeaders: { top: [], middle: [], bottom: [] } };
    }

    // Determine rowSpan for static columns based on maxDepth
    // maxDepth=2 -> 3 rows, maxDepth=1 -> 2 rows, maxDepth=0 -> 1 row
    const staticRowSpan = maxDepth === 2 ? 3 : (maxDepth === 1 ? 2 : 1);

    const staticHeaders = allStaticCols.map(col => ({
      id: col.name,
      label: col.name.replace(/_/g, ' '),
      isStatic: true,
      isSticky: (col.name === rowIdentifier.name),
      rowSpan: staticRowSpan,
      colSpan: 1
    }));

    const dynamicHeaders = { top: [], middle: [], bottom: [] };

    monthColumns.forEach(month => {
      const info = columnInfo[month] || { depth: 0, singleLevelKeys: new Set(), doubleLevelKeys: {} };
      const { depth, singleLevelKeys, doubleLevelKeys } = info;

      const formattedMonth = formatMonthHeader(month);

      if (maxDepth === 2) {
        if (depth === 0) {
          // No nesting: rowSpan=3
          dynamicHeaders.top.push({
            id: month,
            label: formattedMonth,
            colSpan: 1,
            rowSpan: 3
          });
        } else if (depth === 1) {
          // Single-level
          const keys = Array.from(singleLevelKeys);
          dynamicHeaders.top.push({
            id: month,
            label: formattedMonth,
            colSpan: keys.length || 1,
            rowSpan: 1
          });
          // In a maxDepth=2 scenario, single-level keys go to bottom row (skip middle)
          keys.forEach(k => {
            dynamicHeaders.bottom.push({
              id: `${month}${DELIMITER}${k}`,
              label: k
            });
          });
        } else if (depth === 2) {
          // Double-level
          // top-level colSpan = sum of all leaf keys of all first-level keys
          let totalLeafCount = 0;
          Object.keys(doubleLevelKeys).forEach(mKey => {
            totalLeafCount += doubleLevelKeys[mKey].size;
          });

          dynamicHeaders.top.push({
            id: month,
            label: formattedMonth,
            colSpan: totalLeafCount,
            rowSpan: 1
          });

          // Middle row & Bottom row
          Object.keys(doubleLevelKeys).forEach(mKey => {
            const leafKeys = Array.from(doubleLevelKeys[mKey]);
            dynamicHeaders.middle.push({
              id: `${month}${DELIMITER}${mKey}`,
              label: mKey,
              colSpan: leafKeys.length
            });
            leafKeys.forEach(lk => {
              dynamicHeaders.bottom.push({
                id: `${month}${DELIMITER}${mKey}${DELIMITER}${lk}`,
                label: lk
              });
            });
          });
        }
      } else if (maxDepth === 1) {
        // Two rows: top & bottom
        if (depth === 0) {
          // No nesting: rowSpan=2
          dynamicHeaders.top.push({
            id: month,
            label: formattedMonth,
            colSpan: 1,
            rowSpan: 2
          });
        } else if (depth === 1) {
          const keys = Array.from(singleLevelKeys);
          dynamicHeaders.top.push({
            id: month,
            label: formattedMonth,
            colSpan: keys.length || 1,
            rowSpan: 1
          });
          keys.forEach(k => {
            dynamicHeaders.bottom.push({
              id: `${month}${DELIMITER}${k}`,
              label: k
            });
          });
        }
      } else {
        // maxDepth=0: one row
        // all month columns are non-nested
        dynamicHeaders.top.push({
          id: month,
          label: formattedMonth,
          colSpan: 1,
          rowSpan: 1
        });
      }
    });

    return { staticHeaders, dynamicHeaders };
  }, [processedData, allStaticCols, rowIdentifier, columnInfo, monthColumns, maxDepth]);

  const renderCellContent = (row, columnId) => {
    // Check if static column
    const staticCol = allStaticCols.find(col => col.name === columnId);
    if (staticCol) {
      const value = row[columnId];
      if (staticCol.type === 'DATE') {
        return formatDateCell(value) || '-';
      }
      return value || '-';
    }

    // Dynamic column
    const parts = columnId.split(DELIMITER);
    let value = row;
    for (const p of parts) {
      if (value && typeof value === 'object' && p in value) {
        value = value[p];
      } else {
        return '-';
      }
    }

    if (value === null || value === undefined) return '-';
    if (typeof value === 'number') return formatNumber(value);
    if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}$/)) return formatDateCell(value);
    return value;
  };

  const handleExportExcel = () => {
    const { staticHeaders, dynamicHeaders } = getHeaderStructure;
    const ws = XLSX.utils.aoa_to_sheet([]);
    const headerRowsCount = maxDepth === 2 ? 3 : (maxDepth === 1 ? 2 : 1);
    const headerRows = Array.from({ length: headerRowsCount }, () => []);

    // Calculate total columns: static columns + dynamic leaf columns
    let leafHeaders;
    if (maxDepth === 2) {
      leafHeaders = dynamicHeaders.bottom.length > 0 ? dynamicHeaders.bottom : dynamicHeaders.top;
    } else if (maxDepth === 1) {
      leafHeaders = dynamicHeaders.bottom.length > 0 ? dynamicHeaders.bottom : dynamicHeaders.top;
    } else {
      leafHeaders = dynamicHeaders.top;
    }

    const totalColumns = staticHeaders.length + leafHeaders.length;

    // Initialize all header cells for safety
    for (let r = 0; r < headerRowsCount; r++) {
      for (let c = 0; c < totalColumns; c++) {
        headerRows[r][c] = '';
      }
    }

    // Place static headers in the left side
    staticHeaders.forEach((sh, idx) => {
      headerRows[0][idx] = sh.label;
      // Merging if needed handled later
      // Fill other rows if rowSpan>1 with blank since we already placed them in top row
      for (let rr = 1; rr < headerRowsCount; rr++) {
        headerRows[rr][idx] = '';
      }
    });

    // Now place dynamic headers
    let dynamicStartCol = staticHeaders.length;

    // For maxDepth=2: top, middle, bottom
    // For maxDepth=1: top, bottom
    // For maxDepth=0: top only
    // We'll reconstruct the headers by their arrays

    // Top row
    let colPointer = staticHeaders.length;
    dynamicHeaders.top.forEach(h => {
      headerRows[0][colPointer] = h.label;
      // merges
      if (h.rowSpan && h.rowSpan > 1 && h.colSpan && h.colSpan > 1) {
        (ws['!merges'] = ws['!merges'] || []).push({
          s: { r: 0, c: colPointer },
          e: { r: h.rowSpan - 1, c: colPointer + h.colSpan - 1 }
        });
      } else if (h.rowSpan && h.rowSpan > 1) {
        (ws['!merges'] = ws['!merges'] || []).push({
          s: { r: 0, c: colPointer },
          e: { r: h.rowSpan - 1, c: colPointer }
        });
      } else if (h.colSpan && h.colSpan > 1) {
        (ws['!merges'] = ws['!merges'] || []).push({
          s: { r: 0, c: colPointer },
          e: { r: 0, c: colPointer + h.colSpan - 1 }
        });
      }
      colPointer += h.colSpan;
    });

    // Middle row (only if maxDepth=2)
    if (maxDepth === 2 && dynamicHeaders.middle.length > 0) {
      colPointer = staticHeaders.length;
      dynamicHeaders.middle.forEach(h => {
        headerRows[1][colPointer] = h.label;
        if (h.colSpan && h.colSpan > 1) {
          (ws['!merges'] = ws['!merges'] || []).push({
            s: { r: 1, c: colPointer },
            e: { r: 1, c: colPointer + h.colSpan - 1 }
          });
        }
        colPointer += h.colSpan;
      });
    }

    // Bottom row (if maxDepth>0)
    if ((maxDepth === 2 && dynamicHeaders.bottom.length > 0) || (maxDepth === 1 && dynamicHeaders.bottom.length > 0)) {
      colPointer = staticHeaders.length;
      dynamicHeaders.bottom.forEach(h => {
        headerRows[maxDepth === 2 ? 2 : 1][colPointer] = h.label;
        colPointer += 1;
      });
    }

    XLSX.utils.sheet_add_aoa(ws, headerRows);

    // Add data rows
    const dataRows = processedData.map(row => {
      const r = [];
      // Static headers
      staticHeaders.forEach(h => {
        r.push(renderCellContent(row, h.id));
      });
      // Dynamic headers (leaf headers)
      leafHeaders.forEach(h => {
        r.push(renderCellContent(row, h.id));
      });
      return r;
    });

    XLSX.utils.sheet_add_aoa(ws, dataRows, { origin: headerRowsCount });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, `${tableSchema.name}_data.xlsx`);
  };

  if (!Array.isArray(data)) {
    return (
      <Typography variant="body1" align="center" sx={{ p: 2, color: 'text.secondary' }}>
        Invalid data format
      </Typography>
    );
  }

  if (processedData.length === 0) {
    return (
      <Typography variant="body1" align="center" sx={{ p: 2, color: 'text.secondary' }}>
        No data available
      </Typography>
    );
  }

  const { staticHeaders, dynamicHeaders } = getHeaderStructure;

  // Determine leaf headers for row rendering
  let leafHeaders;
  if (maxDepth === 2) {
    leafHeaders = dynamicHeaders.bottom.length > 0 ? dynamicHeaders.bottom : dynamicHeaders.top;
  } else if (maxDepth === 1) {
    leafHeaders = dynamicHeaders.bottom.length > 0 ? dynamicHeaders.bottom : dynamicHeaders.top;
  } else {
    leafHeaders = dynamicHeaders.top;
  }

  const styles = {
    tableCell: {
      padding: '6px 8px',
      fontSize: '0.8rem',
    },
    stickyCell: {
      minWidth: '150px',
      maxWidth: '150px',
      backgroundColor: '#f0f0f0'
    },
    dataCell: {
      minWidth: '100px',
      maxWidth: '100px',
    },
    monthCell: {
      minWidth: '120px',
      maxWidth: '120px',
      textAlign: 'center',
    },
    headerCell: {
      textAlign: 'center',
      borderRight: '1px solid #e0e0e0',
      borderBottom: '2px solid #e0e0e0',
      fontWeight: 'bold',
      backgroundColor: '#f0f0f0'
    }
  };

  return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Paper sx={{ 
          flexGrow: 1, 
          display: 'flex', 
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden' // Add this
        }}>
          <TableContainer sx={{ 
            flexGrow: 1,
            overflow: 'auto' // Change from 'hidden' to 'auto'
          }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              {/* Top Header Row */}
              <TableRow>
                {staticHeaders.map((header) => (
                  <TableCell
                    key={header.id}
                    rowSpan={header.rowSpan}
                    colSpan={header.colSpan}
                    style={{
                      ...styles.tableCell,
                      ...styles.headerCell,
                      position: header.isSticky ? 'sticky' : 'static',
                      left: header.isSticky ? 0 : 'auto',
                      zIndex: header.isSticky ? 3 : 2,
                      ...(header.isSticky ? styles.stickyCell : styles.dataCell),
                    }}
                  >
                    {header.label}
                  </TableCell>
                ))}
                {dynamicHeaders.top.map((header) => (
                  <TableCell
                    key={header.id}
                    colSpan={header.colSpan}
                    rowSpan={header.rowSpan || 1}
                    style={{
                      ...styles.tableCell,
                      ...styles.headerCell,
                      ...styles.monthCell,
                    }}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>

              {/* Middle Header Row (if maxDepth=2) */}
              {maxDepth === 2 && dynamicHeaders.middle.length > 0 && (
                <TableRow>
                  {dynamicHeaders.middle.map((header) => (
                    <TableCell
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        ...styles.tableCell,
                        ...styles.headerCell,
                        ...styles.monthCell,
                      }}
                    >
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              )}

              {/* Bottom Header Row (if needed for maxDepth=1 or maxDepth=2) */}
              {((maxDepth === 2 && dynamicHeaders.bottom.length > 0) ||
                (maxDepth === 1 && dynamicHeaders.bottom.length > 0)) && (
                <TableRow>
                  {dynamicHeaders.bottom.map((header) => (
                    <TableCell
                      key={header.id}
                      style={{
                        ...styles.tableCell,
                        ...styles.headerCell,
                        ...styles.monthCell,
                      }}
                    >
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {processedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <TableRow hover key={rowIndex}>
                    {staticHeaders.map((header) => (
                      <TableCell
                        key={`${rowIndex}-${header.id}`}
                        style={{
                          ...styles.tableCell,
                          position: header.isSticky ? 'sticky' : 'static',
                          left: header.isSticky ? 0 : 'auto',
                          zIndex: header.isSticky ? 1 : 0,
                          borderRight: '1px solid #e0e0e0',
                          ...(header.isSticky ? styles.stickyCell : styles.dataCell),
                        }}
                      >
                        {renderCellContent(row, header.id)}
                      </TableCell>
                    ))}
                    {leafHeaders.map((header) => (
                      <TableCell
                        key={`${rowIndex}-${header.id}`}
                        style={{
                          ...styles.tableCell,
                          ...styles.monthCell,
                          borderRight: '1px solid #e0e0e0',
                        }}
                      >
                        {renderCellContent(row, header.id)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Tooltip title="Download as Excel">
              <IconButton onClick={handleExportExcel}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={processedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </div>
      </Paper>
    </div>
  );
}

export default DataTable;
