import React, { useState, useCallback } from 'react';
import { 
  Box, 
  Stepper, 
  Step, 
  StepLabel, 
  Button, 
  TextField,
  Paper,
  Typography,
  Divider,
  Alert,
} from '@mui/material';
import { mainAuth } from '../../services/firebase';
import { getIdToken } from 'firebase/auth';
import { useAuth } from '../../contexts/AuthContext';
import ColumnDefinition from './ColumnDefinition';
import DynamicColumnBuilder from './DynamicColumnBuilder';
import TableSchemaPreview from './TableSchemaPreview';

const steps = [
  'Basic Information',
  'Column Definition',
  'Dynamic Columns',
  'Review'
];

const initialTableData = {
  name: '',
  description: '',
  rowIdentifier: {
    name: '',
    type: 'Text'
  },
  metadataColumns: [],
  staticColumns: [],
  dynamicColumns: null
};

function CreateTableForm({ onCancel, onTableCreated }) {
  const [activeStep, setActiveStep] = useState(0);
  const [tableData, setTableData] = useState(initialTableData);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { companyName } = useAuth();

  const cleanString = (str) => {
    return str.trim().replace(/\s+/g, '_');
  };

  const cleanTableDocument = (data) => {
    if (!data) return data;
  
    if (Array.isArray(data)) {
      return data.map(item => cleanTableDocument(item));
    }
  
    if (typeof data === 'object') {
      const cleanedObj = {};
      for (const [key, value] of Object.entries(data)) {
        // Clean the key if it's a user-defined field (skip system fields)
        const cleanedKey = key.startsWith('_') ? key : cleanString(key);
        cleanedObj[cleanedKey] = cleanTableDocument(value);
      }
      return cleanedObj;
    }
  
    if (typeof data === 'string') {
      return cleanString(data);
    }
  
    return data;
  };

  const validateCurrentStep = useCallback(() => {
    setError('');
    switch (activeStep) {
      case 0:
        if (!tableData.name.trim()) {
          setError('Table name is required');
          return false;
        }
        if (!tableData.description.trim()) {
          setError('Table description is required');
          return false;
        }
        break;
      case 1:
        if (!tableData.rowIdentifier.name.trim()) {
          setError('Row identifier column is required');
          return false;
        }
        break;
      case 2:
        // Dynamic columns are optional, no validation needed
        break;
      default:
        break;
    }
    return true;
  }, [activeStep, tableData.name, tableData.description, tableData.rowIdentifier.name]);

  const handleNext = useCallback(() => {
    if (validateCurrentStep()) {
      setActiveStep(prevStep => prevStep + 1);
    }
  }, [validateCurrentStep]);

  const handleBack = useCallback(() => {
    setActiveStep(prevStep => prevStep - 1);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      setError('');

      const cleanedTableData = cleanTableDocument(tableData);
      // Initialize display_columns with all static and metadata columns set to true
      const display_columns = {};
      [...cleanedTableData.staticColumns, ...cleanedTableData.metadataColumns].forEach(col => {
        display_columns[col.name] = true;
      });

      const table_doc = {
        ...cleanedTableData,
        display_columns,
        createdAt: new Date().toISOString(),
      };

      // Create table via API
      const idToken = await getIdToken(mainAuth.currentUser);
      const response = await fetch('https://europe-west2-biminsurance-d5783.cloudfunctions.net/create_table', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({ table_doc })
      });

      if (!response.ok) {
        throw new Error('Failed to create table');
      }

      onTableCreated({
        id: cleanedTableData.name,
        ...table_doc
      });
    } catch (err) {
      console.error('Error creating table:', err);
      setError(err.message || 'Failed to create table');
    } finally {
      setIsSubmitting(false);
    }
  }, [companyName, tableData, onTableCreated]);

  const handleBasicInfoChange = useCallback((field, value) => {
    setTableData(prev => ({
      ...prev,
      [field]: value
    }));
  }, []);

  const handleColumnDefinitionChange = useCallback((columnType, columns) => {
    setTableData(prev => ({
      ...prev,
      [columnType]: columns
    }));
  }, []);

  const handleDynamicColumnsChange = useCallback((dynamicColumns) => {
    setTableData(prev => ({
      ...prev,
      dynamicColumns
    }));
  }, []);

  const renderStepContent = useCallback((step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ p: 2 }}>
            <TextField
              fullWidth
              label="Table Name"
              value={tableData.name}
              onChange={(e) => handleBasicInfoChange('name', e.target.value)}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Description"
              value={tableData.description}
              onChange={(e) => handleBasicInfoChange('description', e.target.value)}
              margin="normal"
              required
              multiline
              rows={3}
            />
          </Box>
        );
      case 1:
        return (
          <ColumnDefinition
            rowIdentifier={tableData.rowIdentifier}
            metadataColumns={tableData.metadataColumns}
            staticColumns={tableData.staticColumns}
            onChange={handleColumnDefinitionChange}
          />
        );
      case 2:
        return (
          <DynamicColumnBuilder
            key="dynamic-builder"
            value={tableData.dynamicColumns}
            onChange={handleDynamicColumnsChange}
          />
        );
      case 3:
        return (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Table Schema Preview
            </Typography>
            <TableSchemaPreview schema={tableData} />
          </Box>
        );
      default:
        return null;
    }
  }, [tableData, handleBasicInfoChange, handleColumnDefinitionChange, handleDynamicColumnsChange]);

  return (
    <Paper sx={{ p: 3 }}>
      <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {renderStepContent(activeStep)}

      <Divider sx={{ my: 2 }} />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <Button onClick={onCancel}>
          Cancel
        </Button>
        {activeStep > 0 && (
          <Button onClick={handleBack}>
            Back
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Creating...' : 'Create Table'}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleNext}
          >
            Next
          </Button>
        )}
      </Box>
    </Paper>
  );
}

export default CreateTableForm;