import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Alert,
} from '@mui/material';
import { collection, getDocs, addDoc, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { db, storage } from '../../services/firebase';
import TagSelector from './TagSelector';
import FileStatusCards from './FileStatusCards';

const UploadFiles = ({ companyId }) => {
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(0);
  const [mappings, setMappings] = useState([]);
  const [selectedMapping, setSelectedMapping] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [conflictingFile, setConflictingFile] = useState(null);
  const [recentFiles, setRecentFiles] = useState([]);
  const [historicalFiles, setHistoricalFiles] = useState([]);
  const fileInputRef = useRef(null);

  // Fetch tables and their mappings
  useEffect(() => {
    const fetchTables = async () => {
      try {
        const tablesRef = collection(db, `users/${companyId}/tables`);
        const tablesSnapshot = await getDocs(tablesRef);
        const fetchedTables = tablesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setTables(fetchedTables);
      } catch (err) {
        console.error('Error fetching tables:', err);
        setError('Failed to load tables');
      }
    };

    fetchTables();
  }, [companyId]);

  // Watch cleaned spreadsheets for current table
  useEffect(() => {
    if (tables.length === 0) return;

    const currentTable = tables[selectedTable];
    const twentyFourHoursAgo = new Date();
    twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

    // Query for recent files (last 24 hours)
    const recentQuery = query(
      collection(db, `users/${companyId}/tables/${currentTable.id}/cleaned_spreadsheets`),
      where('created_at', '>=', twentyFourHoursAgo),
      orderBy('created_at', 'desc')
    );

    // Query for historical files (older than 24 hours)
    const historicalQuery = query(
      collection(db, `users/${companyId}/tables/${currentTable.id}/cleaned_spreadsheets`),
      where('created_at', '<', twentyFourHoursAgo),
      orderBy('created_at', 'desc')
    );

    const unsubscribeRecent = onSnapshot(recentQuery, (snapshot) => {
      const files = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setRecentFiles(files);
    }, (error) => {
      console.error('Error watching recent files:', error);
      setError('Failed to watch recent file updates');
    });

    const unsubscribeHistorical = onSnapshot(historicalQuery, (snapshot) => {
      const files = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setHistoricalFiles(files);
    }, (error) => {
      console.error('Error watching historical files:', error);
      setError('Failed to watch historical file updates');
    });

    return () => {
      unsubscribeRecent();
      unsubscribeHistorical();
    };
  }, [companyId, selectedTable, tables]);

  // Fetch mappings when selected table changes
  useEffect(() => {
    const fetchMappings = async () => {
      if (tables.length === 0) return;
      
      try {
        const mappingsRef = collection(db, `users/${companyId}/mappings`);
        const q = query(mappingsRef, where('table', '==', tables[selectedTable].name));
        const mappingsSnapshot = await getDocs(q);
        const fetchedMappings = mappingsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMappings(fetchedMappings);
        setSelectedMapping(''); // Reset mapping selection
        setSelectedTags([]); // Reset tags when mappings are fetched
      } catch (err) {
        console.error('Error fetching mappings:', err);
        setError('Failed to load mappings');
      }
    };

    fetchMappings();
  }, [companyId, selectedTable, tables]);

  const handleTableChange = (event, newValue) => {
    setSelectedTable(newValue);
    setSelectedTags([]); // Reset tags when table changes
  };

  const handleMappingChange = (e) => {
    const mappingId = e.target.value;
    setSelectedMapping(mappingId);

    // Find the selected mapping
    const selectedMappingObj = mappings.find(m => m.id === mappingId);
    
    // If mapping has default tags, convert them to the tag array format and set them
    if (selectedMappingObj?.default_tags) {
      const tagArray = Object.entries(selectedMappingObj.default_tags).map(([category, value]) => ({
        category,
        value
      }));
      setSelectedTags(tagArray);
    } else {
      setSelectedTags([]);
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 30) {
      setError('You can only upload up to 30 files at once.');
      return;
    }
    setFiles(selectedFiles);
    setError('');
  };

  const checkExistingFile = async (fileName) => {
    const currentTable = tables[selectedTable];
    const q = query(
      collection(db, `users/${companyId}/tables/${currentTable.id}/raw_spreadsheets`),
      where('name', '==', fileName)
    );
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    if (files.length === 0 || !selectedMapping) {
      setError('Please select files and a mapping');
      setIsLoading(false);
      return;
    }

    // Validate that all required metadata columns have tags
    const currentTable = tables[selectedTable];
    const requiredCategories = currentTable.metadataColumns.map(col => col.name);
    const missingCategories = requiredCategories.filter(
      category => !selectedTags.some(tag => tag.category === category)
    );

    if (missingCategories.length > 0) {
      setError(`Missing required tags: ${missingCategories.join(', ')}`);
      setIsLoading(false);
      return;
    }

    try {
      // Check for file name conflicts
      for (const file of files) {
        const fileExists = await checkExistingFile(file.name);
        if (fileExists) {
          setConflictingFile(file);
          setConfirmDialogOpen(true);
          setIsLoading(false);
          return;
        }
      }

      await uploadFiles();
    } catch (err) {
      console.error('Upload error:', err);
      setError('Failed to upload files');
    } finally {
      setIsLoading(false);
    }
  };

  const uploadFiles = async () => {
    const currentTable = tables[selectedTable];
    const metadata = {};
    selectedTags.forEach(tag => {
      metadata[tag.category] = tag.value;
    });

    for (const file of files) {
      const fileName = `${file.name}`;
      
      // Upload to Firebase Storage
      const storageRef = ref(storage, `users/${companyId}/tables/${currentTable.id}/raw_spreadsheets/${fileName}`);
      await uploadBytes(storageRef, file);

      // Create Firestore document
      await addDoc(collection(db, `users/${companyId}/tables/${currentTable.id}/raw_spreadsheets`), {
        name: file.name,
        created_at: new Date(),
        prefix: `users/${companyId}/tables/${currentTable.id}/raw_spreadsheets/${fileName}`,
        mapping_id: selectedMapping,
        metadata: metadata
      });
    }

    setSuccess(`${files.length} file(s) uploaded successfully!`);
    clearFiles();
  };

  const clearFiles = () => {
    setFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleConfirmUpload = async () => {
    setConfirmDialogOpen(false);
    setIsLoading(true);
    try {
      await uploadFiles();
    } catch (err) {
      console.error('Upload error:', err);
      setError('Failed to upload files');
    } finally {
      setIsLoading(false);
      setConflictingFile(null);
    }
  };

  if (tables.length === 0) {
    return <Typography>No tables found</Typography>;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Upload Files
      </Typography>

      <Tabs
        value={selectedTable}
        onChange={handleTableChange}
        sx={{ mb: 3 }}
      >
        {tables.map((table) => (
          <Tab key={table.id} label={table.name} />
        ))}
      </Tabs>

      <Box component="form" onSubmit={handleUpload}>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel>Mapping</InputLabel>
          <Select
            value={selectedMapping}
            onChange={handleMappingChange}
            label="Mapping"
          >
            {mappings.map((mapping) => (
              <MenuItem key={mapping.id} value={mapping.id}>
                {mapping.id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ mb: 3 }}>
          <TagSelector
            companyId={companyId}
            tableMetadataColumns={tables[selectedTable].metadataColumns.map(col => col.name)}
            selectedTags={selectedTags}
            onTagsChange={setSelectedTags}
          />
        </Box>

        <TextField
          type="file"
          inputProps={{
            multiple: true,
            accept: '.xlsx,.xls,.csv'
          }}
          onChange={handleFileChange}
          fullWidth
          inputRef={fileInputRef}
        />

        <Button
          type="submit"
          variant="contained"
          disabled={isLoading || files.length === 0 || !selectedMapping}
          sx={{ mt: 2 }}
        >
          {isLoading ? 'Uploading...' : `Upload ${files.length} File(s)`}
        </Button>
      </Box>

      <FileStatusCards 
        recentFiles={recentFiles} 
        historicalFiles={historicalFiles} 
      />

      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Confirm Upload</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A file with the name "{conflictingFile?.name}" already exists. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmUpload} variant="contained">
            Upload Anyway
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UploadFiles;